import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewsComponent } from './views/views.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { MenuListComponent } from './menus/menu-list/menu-list.component';
import { BannerListComponent } from './banners/banner-list/banner-list.component';
import { PopupListComponent } from './popups/popup-list/popup-list.component';
import { BannercmsListComponent } from './cmsbanners/banner-list/banner-list.component';
import { SubmenuListComponent } from './submenus/submenu-list/submenu-list.component';
import { InformationListComponent } from './information/information-list/information-list.component';
import { RifaListComponent } from './rifas/rifa-list/rifa-list.component';
import { DetailFormatListComponent } from './detail-format/detail-format-list/detail-format-list.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { SiteListComponent } from './sites/site-list/site-list.component';
import { CompanyListComponent } from './companies/company-list/company-list.component';
import { LikeListComponent } from './like/like-list/like-list.component';
import { CategoryListComponent } from './categories/category-list/category-list.component';
import { PanelcategoryListComponent } from './panelcategories/panelcategory-list/panelcategory-list.component';
import { MarcaListComponent } from './marcas/marca-list/marca-list.component';
import { ExpenseListComponent } from './expenses/expense-list/expense-list.component';
import { TagListComponent } from './tags/tag-list/tag-list.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { UserListComponent } from './users/user-list/user-list.component';
import { TicketListComponent } from './tickets/ticket-list/ticket-list.component';
import { MonitorListComponent } from './benefits/monitor-list/monitor-list.component';
import { BenefitListComponent } from './benefits/benefit-list/benefit-list.component';
import { HistoryListComponent } from './history/history-list/history-list.component';
import { HistoryticketListComponent } from './history/historyticket-list/history-list.component';
import { AnalyticListComponent } from './analytics/analytic-list/analytic-list.component';
import { RrssComponent } from './analytics/rrss/rrss.component';
import { DashboardFrontComponent } from './dashboard-front/dashboard-front.component';
import { ItemListComponent } from './items/item-list/item-list.component';
import { PollListComponent } from './polls/poll-list/poll-list.component';
import { MasterConfigurationComponent } from './master-configuration/master-configuration.component';
import { CouponListComponent } from './coupons/coupon-list/coupon-list.component';
import { HighlightedBenefitListComponent } from './highlighted-benefits/highlighted-benefit-list/highlighted-benefit-list.component';
import { CustomerListComponent } from './customers/customer-list/customer-list.component';
import { SegmentoListComponent } from './segmentos/segmento-list/segmento-list.component';
import { ContenidosComponent } from './contenidos/contenidos/contenidos.component';
import { NoticiasComponent } from './noticias/noticias/noticias.component';
import { VideosComponent } from './videos/videos/videos.component';
//import { VotacionesListComponent } from './votaciones/votaciones-list/votaciones-list.component';
import { EmailsListComponent } from './emails/emails-list/emails-list.component';
import { EventoListComponent } from './eventos/eventos-list/evento-list.component';
import { NoticiasResourcesListComponent } from './noticiasresources/noticiasresources-list/noticiasresources-list.component';

const routes: Routes = [{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	}, {
		path: 'views',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: ViewsComponent }
		]
	}, {
		path: 'navbars',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: MenuListComponent }
		]
	}, {
		path: 'eventos',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: EventoListComponent }
		]
	}, {
		path: 'emails',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
		  { path: '', component: EmailsListComponent }
		]
		},
		{
		  path: 'noticiasresources',
		  component: DashboardComponent,
		  canActivate: [AuthGuard],
		  children: [
			{ path: '', component: NoticiasResourcesListComponent }
		  ]
		  },
	  {
		path: 'banners',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
		  { path: '', component: BannerListComponent }
		]
	  },
	   {
		path: 'contenidos',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
		  { path: '', component: ContenidosComponent }
		]
	  },
	  {
	   path: 'noticias',
	   component: DashboardComponent,
	   canActivate: [AuthGuard],
	   children: [
		 { path: '', component: NoticiasComponent }
	   ]
	 },
	 {
	  path: 'videos',
	  component: DashboardComponent,
	  canActivate: [AuthGuard],
	  children: [
		{ path: '', component: VideosComponent }
	  ]
	}, {
		path: 'popups',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: PopupListComponent }
		]
	}, {
		path: 'coupons',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: CouponListComponent }
		]
	}, {
		path: 'encuestas',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: PollListComponent }
		]
	} , {
		path: 'submenu',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: SubmenuListComponent }
		]
	}, {
		path: 'informations',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: InformationListComponent }
		]
	},{
		path: 'rifas',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: RifaListComponent }
		]
	}, {
		path: 'detailsformat',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: DetailFormatListComponent }
		]
	}, { path: 'highlighted-benefits',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{path: '', component: HighlightedBenefitListComponent }
		]
	}, {
		path: 'products',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: ProductListComponent }
		]
	}, {
		path: 'sites',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: SiteListComponent }
		]
	}, {
		path: 'companies',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: CompanyListComponent }
		]
	}, {
		path: 'likes',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: LikeListComponent }
		]
	}, {
		path: 'categories',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: CategoryListComponent }
		]
	}, {
		path: 'panelcategories',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: PanelcategoryListComponent }
		]
	},{
		path: 'marcas',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: MarcaListComponent }
		]
	}, {
		path: 'expenses',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: ExpenseListComponent }
		]
	}, {
		path: 'tags',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: TagListComponent }
		]
	}, {
		path: 'users',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: UserListComponent }
		]
	}, {
		path: 'customers',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: CustomerListComponent }
		]
	}, {
		path: 'segmentos',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: SegmentoListComponent }
		]
	}, {
		path: 'master-configuration',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: MasterConfigurationComponent }
		]
	}, {
		path: 'tickets',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: TicketListComponent }
		]
	}, {
		path: 'monitor',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: MonitorListComponent }
		]
	}, {
		path: 'benefits',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: BenefitListComponent },
			{ path: ':id_alianza/:id_categoria',  component: BenefitListComponent }
		]
	},
	{
		path: 'history',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: HistoryListComponent }
		]
	}, 
	{
		path: 'history_ticket',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: HistoryticketListComponent }
		]
	}, 
	{
		path: 'analytics',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: AnalyticListComponent }
		]
	},
	{
		path: 'rrss',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: RrssComponent }
		]
	}, {
		path: 'cmsbanners',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: BannercmsListComponent }
		]
	},{
		path: 'items',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: '', component: ItemListComponent }
		]
	}, {
		path: 'front',
		component: DashboardFrontComponent
	}];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
