import { Component, OnInit, ViewChild } from '@angular/core';
import { ResourcesService } from '../../shared/services/resources.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from '../../shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
//import { NoticiasResourcesComponent } from '../noticiasresources/noticiasresources.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { INoticiaResourceElement } from '../../shared/interfaces/inoticiaresource-element';
import { JwtService } from '../../shared/services/jwt.service';
import { NoticiasResourcesComponent } from '../noticiasresources/noticiasresources.component';

@Component({
  selector: 'app-noticiasresources-list',
  templateUrl: './noticiasresources-list.component.html',
  styleUrls: ['./noticiasresources-list.component.scss']
})
export class NoticiasResourcesListComponent implements OnInit {

	public displayedColumns: string[] = [
        'fecha_creacion',
        'nombre_resource',
		'categoria',
		'link',
		'actions'
	];

	
	
	loading: boolean;
	dataSource = new MatTableDataSource<INoticiaResourceElement>();
	selection = new SelectionModel<INoticiaResourceElement>(true, []);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	constructor(
		private resourcesService: ResourcesService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getResourcesNoticias();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getResourcesNoticias(): void {
		this.resourcesService.getResourcesNoticias().subscribe(
			(data: any) => {
				this.dataSource.data = data as INoticiaResourceElement[];
			},(error) => {
				console.error(error);
			}, () => {
				this.loading = false;
			}
		);
	}

	openModal(data?: any): void {
		this.dialog.open(NoticiasResourcesComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
			  this.loading = true;
			  this.getResourcesNoticias();
			}
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				this.resourcesService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					console.error(error);
				}, () => {
					this.getResourcesNoticias();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
