import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { PopupService } from '../../shared/services/popup.service';
import { ResourceListComponent } from 'src/app/resources/resource-list/resource-list.component';
import { ResourcesService } from '../../shared/services/resources.service';
import { CouponService } from 'src/app/shared/services/coupon.service';
import { ICouponElement } from 'src/app/shared/interfaces/icoupon-element';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Observable } from 'rxjs';
import { PollService } from 'src/app/shared/services/poll.service';
import { IPollElement } from 'src/app/shared/interfaces/ipoll-element';

@Component({
	selector: 'app-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

	idComponent: number;
	nameComponent = ' Popup';
	registerForm: FormGroup;
	loading: boolean = false;
	coupons: Observable<ICouponElement[]>;
	forms: Observable<IPollElement[]>;

	constructor(
		public dialogRef: MatDialogRef<PopupComponent>,
		private notificationService: NotificationsService,
		public popupService: PopupService,
		private couponService: CouponService,
		private pollService: PollService,
		private resourcesService: ResourcesService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
		/* var inputNode: any =  this.registerForm.get('imagen').value;
		inputNode = inputNode.replace('/resource/popup/','');
		this.registerForm.controls.imagen.setValue(inputNode); */
		
		if (this.data == null) {
			this.registerForm = this.formBuilder.group({
				id_pop_up: null,
				nombre: '',
				imagen: null,
				link: null,
				activo: [true, Validators.required],
				id_cupon: Array(),
				id_encuesta : ''
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando un sitio */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			let idComponent = this.data.id_pop_up;
			this.idComponent = idComponent;
			/* Se obtienen los cupones del pop-up */
			let selectedCoupons = [];
			let array = this.data.cupones;
			if (array instanceof Array) {
				array.forEach(element => {
					selectedCoupons.push(element.id_cupon);
				});
			}

			/* Se obtiene la encuesta del pop-up */
			let idEncuesta = !this.data.encuesta ? '' : this.data.encuesta.id_encuesta;

			this.registerForm = this.formBuilder.group({
				id_pop_up: idComponent,
				nombre: [this.data.nombre, Validators.required],
				imagen: this.data.imagen,
				link: this.data.link,
				activo: [this.data.activo, Validators.required],
				id_cupon: [selectedCoupons],
				id_encuesta: idEncuesta
			});
		}

		/* Se obtienen los cupones */
		this.coupons = this.couponService.getCoupons();

		/* Se obtienen los formularios */
		this.forms = this.pollService.getPolls();
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('id_pop_up').value) {
				this.popupService.store(this.registerForm.value).subscribe((res) => {
					this.notificationService.success(':: Enviado satisfactoriamente');
					this.onClose(true);
				}, (error: any) => {
					this.notificationService.warn(`:: Error creando pop-up: ${error.message}`);
				}).add(() => {
					this.loading = false;
				})
			} else {
				this.popupService.update(this.registerForm.value).subscribe((res) => {
					this.notificationService.success(':: Actualizado satisfactoriamente');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error editando pop-up: ${error.message}`);
				}).add(() => {
					this.loading = false;
				})
			}
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

}
