import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IPollElement } from '../interfaces/ipoll-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/encuestas";

@Injectable({
	providedIn: 'root'
})
export class PollService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getPolls(): Observable<IPollElement[]> {
		return this.httpClient.get<IPollElement[]>(`/bff/admin${PATH}`);
	}

	store(data: any) {
		let questions = data.preguntas;

		/* PREGUNTAS */
		let preguntas:string[] = [];
		let placeholders:string[] = [];
		let idTipoPreguntaEncuesta:number[] = [];
		let opciones:string[] = [];
		let clases:string[] = [];
		let tiposInputs:string[] = [];
		if (Array.isArray(questions)) {
			questions.forEach((value: any, key: any) => {
				/* Se agregan las opciones a la pregunta*/
				let opcionesJoined = '';
				if (Array.isArray(value.opciones)) {
					opcionesJoined = value.opciones.map(function(elem) {
						return elem.opcion;
					}).join("|");
				}
				preguntas.push(value.pregunta);
				placeholders.push(value.placeholder);
				idTipoPreguntaEncuesta.push(value.id_tipo_pregunta_encuesta);
				opciones.push(opcionesJoined);
				clases.push(value.clase);
				tiposInputs.push(value.tipo_input);
			});
		}

		const initDate = moment(data.fecha_inicio);
		const endDate = moment(data.fecha_fin);
	
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', (data.nombre))
			.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('imagen', data.imagen)
			.set('activo', (data.activo))
			.set('orden_aleatorio', (data.orden_aleatorio))
			.set('mostrar_solo', (data.mostrar_solo))
			.set('pregunta', preguntas.join(','))
			.set('placeholder', placeholders.join(','))
			.set('id_tipo_pregunta', idTipoPreguntaEncuesta.join(','))
			.set('opciones', opciones.join(','))
			.set('clase', clases.join(','))
			.set('tipo_input', tiposInputs.join(','));
		return this.httpClient.post<any>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<IPollElement[]> {
		let questions = data.preguntas;

		/* PREGUNTAS */
		let preguntas:string[] = [];
		let placeholders:string[] = [];
		let idTipoPreguntaEncuesta:number[] = [];
		let opciones:string[] = [];
		let clases:string[] = [];
		let tiposInputs:string[] = [];
		if (Array.isArray(questions)) {
			questions.forEach((value: any) => {
				/* Se agregan las opciones a la pregunta*/
				const opcionesPregunta = value.opciones;
				let opcionesJoined = '';
				if (Array.isArray(opcionesPregunta)) {
					opcionesJoined = opcionesPregunta.join("|");
				}
				preguntas.push(value.pregunta);
				placeholders.push(value.placeholder);
				idTipoPreguntaEncuesta.push(value.id_tipo_pregunta_encuesta);
				opciones.push(opcionesJoined);
				clases.push(value.clase);
				tiposInputs.push(value.tipo_input);
			});
		}

		const initDate = moment(data.fecha_inicio);
		const endDate = moment(data.fecha_fin);
	
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', (data.nombre))
			.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('imagen', data.imagen)
			.set('activo', (data.activo))
			.set('orden_aleatorio', (data.orden_aleatorio))
			.set('mostrar_solo', (data.mostrar_solo))
			.set('pregunta', preguntas.join(','))
			.set('placeholder', placeholders.join(','))
			.set('id_tipo_pregunta', idTipoPreguntaEncuesta.join(','))
			.set('opciones', opciones.join(','))
			.set('clase', clases.join(','))
			.set('tipo_input', tiposInputs.join(','));
	
		return this.httpClient.put<any>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}
}
