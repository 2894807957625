import { Component, OnInit, ViewChild } from '@angular/core';
import { RifaService } from '../../shared/services/rifa.service';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateRifaComponent } from '../create-rifa/create-rifa.component';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { IRaffleElement } from 'src/app/shared/interfaces/iraffle-element';

@Component({
	selector: 'app-rifa-list',
	templateUrl: './rifa-list.component.html',
	styleUrls: ['./rifa-list.component.scss']
})
export class RifaListComponent implements OnInit {

	loading: boolean = true;
	dataSource = new MatTableDataSource<IRaffleElement>();
	selection = new SelectionModel<IRaffleElement>(true, []);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public displayedColumns: string[] = [
		'nombre',
		'titulo',
		'cantidad_numeros',
		'numeros_maximos',
		'fecha_creacion',
		'estado',
		'actions'
	];

	constructor(
		private rifaService: RifaService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) { }

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getRaffles();
		} else {
			this.notificationService.warn(':: Sesión expirada. Por favor, vuelva a iniciar sesión');
		}
	}

	getRaffles() {
		this.loading = true; 
		this.rifaService.getRaffles().subscribe((data) => {
			this.dataSource.data = data;
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo rifas: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(data?: IRaffleElement) {
		this.dialog.open(CreateRifaComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getRaffles();
			}
		});
	}

	delete(id: number) {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.rifaService.delete(id).subscribe((data: any) => {
					this.notificationService.success(':: Registro eliminado satisfactoriamente');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando rifa: ${error.message}`);
				}).add(() => {
					this.getRaffles();
				});
			}
		});
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
