import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IRaffleElement } from '../interfaces/iraffle-element';

import { CustomURLEncoder } from '../../urlencoder/component';

const PATH: string = "/admin/rifas";

@Injectable({
	providedIn: 'root'
})
export class RifaService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getRaffles(): Observable<IRaffleElement[]> {
		return this.httpClient.get<IRaffleElement[]>(`/bff${PATH}`);
	}
	
	getRaffle(id: number): Observable<IRaffleElement> {
		return this.httpClient.get<IRaffleElement>(`/bff${PATH}/${id}`);
	}

	store(data: any): Observable<IRaffleElement>  {
		let cantidades: string[] = [];
		let costos: string[] = [];
		let costosRifa = data.costos_rifa;
		if (Array.isArray(costosRifa)) {
			costosRifa.sort((a, b) => {
				return a.cantidad - b.cantidad;
			});
			costosRifa.forEach((value: any, key: any) => {
				cantidades.push(value.cantidad);
				costos.push(value.costo);
			});
		}

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('titulo', data.titulo)
			.set('subtitulo', data.subtitulo)
			.set('imagen', data.imagen)
			.set('fondo', data.fondo)
			.set('icono_animado', data.icono_animado)
			.set('cantidad_numeros', data.cantidad_numeros)
			.set('numeros_maximos', data.numeros_maximos)
			.set('estado', data.estado)
			.set('fecha_creacion', data.fecha_creacion)
			.set('id_scsubmenu', data.id_scsubmenu)
			.set('fondo_modal', data.fondo_modal)
			.set('descripcion_premio', data.descripcion_premio)
			.set('condiciones_legales', data.condiciones_legales)
			.set('cantidad', cantidades.join(','))
			.set('costo', costos.join(','));

		return this.httpClient.post<IRaffleElement>(
			`/bff${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<IRaffleElement> {
		let cantidades: string[] = [];
		let costos: string[] = [];
		let costosRifa = data.costos_rifa;
		if (Array.isArray(costosRifa)) {
			costosRifa.sort((a, b) => {
				return a.cantidad - b.cantidad;
			});
			costosRifa.forEach((value: any, key: any) => {
				cantidades.push(value.cantidad);
				costos.push(value.costo);
			});
		}
	
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('titulo', data.titulo)
			.set('subtitulo', data.subtitulo)
			.set('imagen', data.imagen)
			.set('fondo', data.fondo)
			.set('icono_animado', data.icono_animado)
			.set('cantidad_numeros', data.cantidad_numeros)
			.set('numeros_maximos', data.numeros_maximos)
			.set('estado', data.estado)
			.set('fecha_creacion', data.fecha_creacion)
			.set('id_scsubmenu', data.id_scsubmenu)
			.set('fondo_modal', data.fondo_modal)
			.set('descripcion_premio', data.descripcion_premio)
			.set('condiciones_legales', data.condiciones_legales)
			.set('cantidad', cantidades.join(','))
			.set('costo', costos.join(','));

		return this.httpClient.put<IRaffleElement>(
			`/bff${PATH}/${data.id_rifa}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id: number) {
		return this.httpClient.delete(`/bff${PATH}/${id}`);
	}
}
