import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-aside',
	templateUrl: './aside.component.html',
	styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

	@Input() scotiaID: string;
	@Input() role: string;
	public isAdmin: boolean;

	constructor(private router: Router) { }

	ngOnInit() {
		this.isAdmin = (this.role == 'ROLE_ADMIN') ? true : false;
		/* Arreglamos el bug del menú pegado */
		$(() => { const trees: any = $('[data-widget="tree"]'); trees.tree(); });
	}
	
	/* Diseño y Juegos */
	getContenidos() {
		this.router.navigate(['contenidos']);
		return false;
	  }
	
	  getNoticias() {
		this.router.navigate(['noticias']);
		return false;
	  }
	
	  getVideos() {
		this.router.navigate(['videos']);
		return false;
	  }
	
	  getEmails(){
		this.router.navigate(['emails']);
		return false;
		}

		getEventos(){
			this.router.navigate(['eventos']);
			return false;
			}
	
	  getNoticiasResources(){
		  this.router.navigate(['noticiasresources']);
		  return false;
		}

		getVotaciones(){
			this.router.navigate(['votaciones']);
			return false;
			}
	getNavBars() {
		this.router.navigate(['navbars']);
		return false;
	}

	getBanners() {
		this.router.navigate(['banners']);
		return false;
	}

	getPopups() {
		this.router.navigate(['popups']);
		return false;
	}

	getCoupons() {
		this.router.navigate(['coupons']);
		return false;
	}

	getInformations() {
		this.router.navigate(['informations']);
		return false;
	}

	getRifas() {
		this.router.navigate(['rifas']);
		return false;
	}

	getPolls() {
		this.router.navigate(['encuestas']);
		return false;
	}


	/* Mantenedores */

	getHighlightedBenefits() {
		this.router.navigate(['highlighted-benefits']);
		return false;
	}

	getProducts() {
		this.router.navigate(['products']);
		return false;
	}

	getSites() {
		this.router.navigate(['sites']);
		return false;
	}

	getLikes() {
		this.router.navigate(['likes']);
		return false;
	}

	getTags() {
		this.router.navigate(['tags']);
		return false;
	}

	getCategories() {
		this.router.navigate(['categories']);
		return false;
	}

	getItems() {
		this.router.navigate(['items']);
		return false;
	}

	getViews() {
		this.router.navigate(['views']);
		return false;
	}

	/* Accesos */

	getMasterConfiguration() {
		this.router.navigate(['master-configuration']);
		return false;
	}

	getUsers() {
		this.router.navigate(['users']);
		return false;
	}

	getCustomers() {
		this.router.navigate(['customers']);
		return false;
	}

	getSegmentos() {
		this.router.navigate(['segmentos']);
		return false;
	}

	/* Adminscotia */

	getHistory() {
		this.router.navigate(['history']);
		return false;
	}

	getHistoryTicket() {
		this.router.navigate(['history_ticket']);
		return false;
	}
	
	getExpenses() {
		this.router.navigate(['expenses']);
		return false;
	}

	getRrss() {
		this.router.navigate(['rrss']);
		return false;
	}

	getAnalytics() {
		this.router.navigate(['analytics']);
		return false;
	}

}