import { Component, OnInit } from '@angular/core';
import { LikeService } from 'src/app/shared/services/like.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-create-like',
  templateUrl: './create-like.component.html',
  styleUrls: ['./create-like.component.scss']
})
export class CreateLikeComponent implements OnInit {

  public loading: boolean;
  public status: boolean;
  public submitted: boolean;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Gusto';

  constructor(
    public dialogRef: MatDialogRef<CreateLikeComponent>,
    private notificationService: NotificationsService,
    public likeService: LikeService,
    private resourcesService: ResourcesService,
    private dialog: MatDialog
  ) {
    this.status = false;
    this.submitted = false;
    this.loading = false;
    this.idComponent = this.likeService.registerForm.get('id').value;
  }

  ngOnInit() {
    this.reset = this.likeService.registerForm.get('id').value;
  }

  get f() { return this.likeService.registerForm.controls; }

  onSubmit() {

    if (this.likeService.registerForm.valid) {
      if (!this.likeService.registerForm.get('id').value) {
        this.likeService.store(this.likeService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.likeService.update(this.likeService.registerForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onChange($event: any) {
    this.status = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.likeService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

}
