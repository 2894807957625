import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IBannerElement } from '../interfaces/ibanner-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/banners";

@Injectable({
	providedIn: 'root'
})
export class BannerService {

	constructor(
		protected httpClient: HttpClient
	) {	}

	getBanners(): Observable<IBannerElement[]> {
		return this.httpClient.get<IBannerElement[]>(`/bff/admin${PATH}`);
	}

	getBanner(id: number): Observable<IBannerElement> {
		return this.httpClient.get<IBannerElement>(`/bff/admin${PATH}/${id}`);
	}

	store(data: any): Observable<IBannerElement> {
		const initDate = moment(data.date_init);
		const endDate = moment(data.date_end);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
		.set('blank', (data.blank) ? '1' : '0')
		.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
		.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
		.set('orden', data.order)
		.set('ruta', data.ruta)
		.set('url', data.url)
		.set('tipo', data.tipo)
		.set('estado', (data.status) ? '1' : '0')
		.set('responsive', (data.responsive) ? '1' : '0')
		.set('nombre', data.name)
		.set('html', data.html)
		.set('tipo_perfilamiento', data.tipo_perfilamiento)
		.set('id_segmento', data.id_segmento);

		return this.httpClient.post<any>(
		'/bff/admin/banners',
		body.toString(),
		{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<IBannerElement> {
		const initDate = moment(data.date_init);
		const endDate = moment(data.date_end);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
		.set('blank', (data.blank) ? '1' : '0')
		.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
		.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
		.set('orden', data.order)
		.set('ruta', data.ruta)
		.set('url', data.url)
		.set('tipo', data.tipo)
		.set('estado', (data.status) ? '1' : '0')
		.set('responsive', (data.responsive) ? '1' : '0')
		.set('nombre', data.name)
		.set('html', data.html)
		.set('tipo_perfilamiento', data.tipo_perfilamiento)
		.set('id_segmento', data.id_segmento);

		return this.httpClient.put<IBannerElement>(
		'/bff/admin/banners/' + data.$key,
		body.toString(),
		{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
	}

	delete(id: number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}

}
