import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { CKEditorConstants } from 'src/app/shared/constants/ckeditor-constants';
import { IRaffleElement } from 'src/app/shared/interfaces/iraffle-element';
import { ISubNavBarElement } from 'src/app/shared/interfaces/isubnavbar-element';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { RifaService } from 'src/app/shared/services/rifa.service';
import { SubnavbarService } from 'src/app/shared/services/subnavbar.service';

@Component({
	selector: 'app-create-rifa',
	templateUrl: './create-rifa.component.html',
	styleUrls: ['./create-rifa.component.scss']
})
export class CreateRifaComponent implements OnInit {

	public loading: boolean;
	public ckeConfig: any;
	public idComponent: number;
	public nameComponent = 'Rifa';
	registerForm: FormGroup;
	submenus: ISubNavBarElement[];
	loadingSubmenus: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<CreateRifaComponent>,
		private notificationService: NotificationsService,
		public rifaService: RifaService,
		public subMenuService: SubnavbarService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) private data: IRaffleElement
	) {	}

	ngOnInit() {
		this.ckeConfig = CKEditorConstants.ckeConfig;

		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando una encuesta */
			this.registerForm = this.formBuilder.group({
				id_rifa: null,
				nombre: ['', Validators.required],
				titulo: ['', Validators.required],
				subtitulo: [''],
				imagen: ['', Validators.required],
				icono_animado: ['', Validators.required],
				fondo: ['', Validators.required],
				fondo_modal : ['', Validators.required],
				cantidad_numeros: ['', Validators.required],
				numeros_maximos: ['', Validators.required],
				descripcion_premio: [''],
				condiciones_legales: ['', Validators.required],
				estado: true,
				id_scsubmenu: [null, Validators.required],
				costos_rifa: this.formBuilder.array([], Validators.required)
			});
		} else {
			/* Se llena la información del formulario */
			this.idComponent = this.data.id_rifa;
			let costosRifa = this.formBuilder.array([], Validators.required);
			for (let i = 0; i < this.data.costos_rifa.length; i++) {
				const raffleCost = this.data.costos_rifa[i];
				const formGroup = this.formBuilder.group({
					cantidad: [
						raffleCost.cantidad,
						Validators.compose([
							Validators.min(1),
							Validators.required
						])
					],
					costo: [
						raffleCost.costo, 
						Validators.compose([
							Validators.min(1),
							Validators.required
						])
					]
				});
				costosRifa.push(formGroup);
			}
			this.registerForm = this.formBuilder.group({
				id_rifa: this.idComponent,
				nombre: [this.data.nombre, Validators.required],
				titulo: [this.data.titulo, Validators.required],
				subtitulo: [this.data.subtitulo],
				imagen: [this.data.imagen, Validators.required],
				icono_animado: [this.data.icono_animado, Validators.required],
				fondo: [this.data.fondo, Validators.required],
				fondo_modal : [this.data.fondo_modal, Validators.required],
				cantidad_numeros: [this.data.cantidad_numeros, Validators.required],
				numeros_maximos: [this.data.numeros_maximos, Validators.required],
				descripcion_premio: [this.data.descripcion_premio],
				condiciones_legales: [this.data.condiciones_legales, Validators.required],
				estado: this.data.estado,
				id_scsubmenu: [this.data.id_scsubmenu, Validators.required],
				costos_rifa: costosRifa
			});
		}

		// Submenús
		this.subMenuService.getSubmenus().subscribe((res) => {
			this.loadingSubmenus = false;
			this.submenus = res.filter(element => {
				return element.id_tipo_submenu > 39 && element.id_tipo_submenu < 45;
			});
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo submenús: ${error.message}`);
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			let saveRaffleObservable: Observable<IRaffleElement>;
			if (!this.registerForm.get('id_rifa').value) {
				saveRaffleObservable = this.rifaService.store(this.registerForm.value)
			} else {
				saveRaffleObservable = this.rifaService.update(this.registerForm.value)
			}
			saveRaffleObservable.subscribe(() => {
				this.notificationService.success(':: Guardado satisfactoriamente');
				this.onClose(true);
			}, (error) => {
				this.notificationService.warn(`:: Error guardando rifa: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

	agregarCosto() {
		let costosRifa = this.registerForm.controls['costos_rifa'] as FormArray;
		const newCosto = this.formBuilder.group({
			cantidad: [
				costosRifa.length + 1, 
				Validators.compose([
					Validators.min(1),
					Validators.required
				])
			],
			costo: [
				(costosRifa.length + 1)*1000,
				Validators.compose([
					Validators.min(1),
					Validators.required
				])
			]
		});
		costosRifa.push(newCosto);
	}

	deleteCosto(i: number) {
		let costosRifa = this.registerForm.controls['costos_rifa'] as FormArray;
		costosRifa.removeAt(i);
	}
}
