import { ISegmentTypeElement } from "../interfaces/isegment-type-element";

export class CSegmentsConstants {

	public static TiposPerfilamientos: ISegmentTypeElement[] = [
		{valor: 1, descripcion:'Público para todos'},
		{valor: 2, descripcion:'Mostrar sólo a algunos Clientes'},
		{valor: 3, descripcion:'Mostrar a todos excepto a algunos Clientes'}
	];

}