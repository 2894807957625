import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { BannerService } from '../../shared/services/banner.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { BannerComponent } from '../banner/banner.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IBannerElement } from 'src/app/shared/interfaces/ibanner-element';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
	selector: 'app-banner-list',
	templateUrl: './banner-list.component.html',
	styleUrls: ['./banner-list.component.scss']
})
export class BannerListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
		'orden',
		'ruta',
		'estado',
		'fecha_inicio',
		'fecha_fin',
		'tipo',
		'actions'
	];

	loading: boolean = false;
	dataSource = new MatTableDataSource<IBannerElement>();
	selection = new SelectionModel<IBannerElement>(true, []);

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		private bannerService: BannerService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) { }

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getBanners();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getBanners(): void {
		this.loading = true;
		this.bannerService.getBanners().subscribe((data: any) => {
			this.dataSource.data = data as IBannerElement[];
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo banners: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(data?: any): void {
		this.dialog.open(BannerComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getBanners();
			}
		});
	}

	delete(id: number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.bannerService.delete(id).subscribe((data: any) => {
					this.notificationService.success(':: Registro eliminado satisfactoriamente');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando banner: ${error.message}`);
				}).add(() => {
					this.getBanners();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
