import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';

import {CustomURLEncoder} from '../../urlencoder/component';
import { IHighlightedBenefitElement } from '../interfaces/ihighlighted-benefit-element';

const moment = _moment;
moment.locale('es');

const PATH: string = "/beneficios-destacados";

@Injectable({
	providedIn: 'root'
})
export class HighlightedBenefitService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getHighlightedBenefits(): Observable<IHighlightedBenefitElement[]> {
		return this.httpClient.get<IHighlightedBenefitElement[]>(`/bff/admin${PATH}`);
	}

	getHighlightedBenefit(id:number): Observable<IHighlightedBenefitElement> {
		return this.httpClient.get<IHighlightedBenefitElement>(`/bff/admin${PATH}/${id}`);
	}

	store(data: any) {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)	
			.set('titulo', data.titulo)
			.set('subtitulo', data.subtitulo)
			.set('descuento', data.descuento)
			.set('descripcion', data.descripcion)
			.set('condiciones', data.condiciones)
			.set('imagen', data.imagen)
			.set('imagen_logo', data.imagen_logo)
			.set('url', data.url)
			.set('new_tab', data.new_tab)
			.set('orden', data.orden)
			.set('lunes', data.dias_semana.lunes)
			.set('martes', data.dias_semana.martes)
			.set('miercoles', data.dias_semana.miercoles)
			.set('jueves', data.dias_semana.jueves)
			.set('viernes', data.dias_semana.viernes)
			.set('sabado', data.dias_semana.sabado)
			.set('domingo', data.dias_semana.domingo)
			.set('activo', data.activo);

		return this.httpClient.post<any>(
			'/bff/admin' + PATH,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<IHighlightedBenefitElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('titulo', data.titulo)
			.set('subtitulo', data.subtitulo)
			.set('descuento', data.descuento)
			.set('descripcion', data.descripcion)
			.set('condiciones', data.condiciones)
			.set('imagen', data.imagen)
			.set('imagen_logo', data.imagen_logo)
			.set('url', data.url)
			.set('new_tab', data.new_tab)
			.set('orden', data.orden)
			.set('lunes', data.dias_semana.lunes)
			.set('martes', data.dias_semana.martes)
			.set('miercoles', data.dias_semana.miercoles)
			.set('jueves', data.dias_semana.jueves)
			.set('viernes', data.dias_semana.viernes)
			.set('sabado', data.dias_semana.sabado)
			.set('domingo', data.dias_semana.domingo)
			.set('activo', data.activo);

		return this.httpClient.put<any>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}
}
