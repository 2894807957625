import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { BenefitService } from '../../shared/services/benefit.service';
import { CompanyService } from '../../shared/services/company.service';
import { IBenefitElement } from 'src/app/shared/interfaces/ibenefit-element';
import { PanelcategoryService } from '../../shared/services/panelcategory.service';
import { IPanelcategoryElement } from 'src/app/shared/interfaces/ipanelcategory-element';
import { ICompanyElement } from 'src/app/shared/interfaces/icompany-element';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import * as Chart from 'chart.js'


@Component({
  selector: 'app-monitor-list',
  templateUrl: './monitor-list.component.html',
  styleUrls: ['./monitor-list.component.scss']
})
export class MonitorListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<IBenefitElement>();
  dataSource2 = new MatTableDataSource<IPanelcategoryElement>();
  dataSource3 = new MatTableDataSource<ICompanyElement>();
  //dataSource4 = new MatTableDataSource<IBenefitElement>();
  

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public displayedColumns: string[] = [
    
    'nombre_alianza',
    'nombre_categoria',
    'cantidad_beneficios'
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public benefitService: BenefitService,
    public panelcategoryService: PanelcategoryService,
    public companyService : CompanyService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //foreach

      this.getalianzas();
      this.getcategories();
      this.gettotals();

      //this.ngAfterViewInit(this.dataSource.data);
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }


  llenar_array(arr, numero){

    return Array.apply(null, {length: numero * arr.length})
        .map(function(e,i){return arr[i % arr.length]});

  }

  gettotals() {
    this.benefitService.getTotals().subscribe(data => {
      this.dataSource.data = data;
    }, (error : any) => {
      console.error(error);
    }, () => { 
       this.ngAfterViewInit(this.dataSource.data);
       this.grafico2(this.dataSource.data);
       this.grafico3(this.dataSource.data);
       this.grafico4(this.dataSource.data);
      
      this.loading = false;
       });
  }

  getalianzas() {
    this.companyService.getCompanies().subscribe((data : any) => {
      this.dataSource3.data= data;
    }, (error : any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  getcategories(){
    this.panelcategoryService.getCategories().subscribe((data : any) => {
      this.dataSource2.data= data;
    }, (error : any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  ngAfterViewInit(data) {
    var labels = Object.keys(data[0]);
    labels.shift();

    var str = '';
    for (var i = 0; i < labels.length; i ++){
      str += '<canvas id="chart'+i+'" width="700" height="400"></canvas>';
    }
    
    document.getElementById('graficos').innerHTML =str; 

    var bancos = this.dataSource3.data.map(function(e) {
      return e.nombre_alianza;
    });

    var colores = this.dataSource3.data.map(function(e) {
      var auxcol = '';
      auxcol += parseInt(e.color.substring(1,3), 16).toString()+",";
      auxcol += parseInt(e.color.substring(3,5), 16).toString()+",";
      auxcol += parseInt(e.color.substring(5,7), 16).toString();
      return 'rgba('+auxcol+', 0.5)';
    });



    for (var i = 0; i < labels.length; i ++){

      var datatot = data.map(function(e) {
        return e[labels[i]];
      });
      //console.log(datatot);

      var l = labels[i];
      var canvas = document.getElementById('chart'+i) as HTMLCanvasElement;
      var ctx = canvas.getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: bancos,
          datasets: [{
            label: l,
            data: datatot,
            backgroundColor: colores,
            borderWidth: 1
          }]
        },
        options: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: l,
            position: 'top',
            fontSize: 16,
            padding: 20
          },

          responsive: true,
          display:true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    }
  }

  grafico2(data) {
    var labels = Object.keys(data[0]);
    labels.shift();
    
    
    var bancos = this.dataSource3.data.map(function(e) {
      return e.nombre_alianza;
    });

    var colores = this.dataSource3.data.map(function(e) {
      var auxcol = '';
      auxcol += parseInt(e.color.substring(1,3), 16).toString()+",";
      auxcol += parseInt(e.color.substring(3,5), 16).toString()+",";
      auxcol += parseInt(e.color.substring(5,7), 16).toString();
      return 'rgba('+auxcol+', 0.5)';
    });

    var datatot = [];
    Object.keys(data).forEach(function(elem) {
      var sum = 0;      
      var item = data[elem];
      Object.keys(item).forEach(function(key){
        if (key != 'imagen' ){
          sum+= parseInt(item[key]);
        }
      });
      
      //console.log(sum);
      datatot.push(sum);
    });  
    //console.log(datatot);


    var canvas = document.getElementById('grafico2') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');   
    let myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: bancos,
        datasets: [{
          label: "Totales",
          data: datatot,
          backgroundColor: colores,
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: "Totales",
          position: 'top',
          fontSize: 16,
          padding: 20
        },

        responsive: true,
        display:true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });  
  }

  grafico3(data) {
    var labels = Object.keys(data[0]);
    labels.shift();
    
    
    var categs = this.dataSource2.data.map(function(e) {
      return e.nombre_categoria;
    });

    var colores = this.dataSource3.data.map(function(e) {
      var auxcol = '';
      auxcol += parseInt(e.color.substring(1,3), 16).toString()+",";
      auxcol += parseInt(e.color.substring(3,5), 16).toString()+",";
      auxcol += parseInt(e.color.substring(5,7), 16).toString();
      return 'rgba('+auxcol+', 0.5)';
    });

    colores = this.llenar_array(colores, categs.length);

        var datatot = [];
    
    const result = data.reduce((sums, obj) => Object.keys(obj).reduce((s, k) => {    
    k === 'imagen' || (s[k] = (s[k] || 0) + +obj[k]);
    
    return s;
    }, sums), {});

    var aux0 = Object.values(result);
    var aux = Object.values(data[5]);
    aux.shift();
    for(var i = 0; i< aux0.length; i++){
      //aux[i] = parseInt(aux[i]);
      datatot[i] = [ aux0[i] , aux[i] ];
    }


    var canvas = document.getElementById('grafico3') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
      let myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: categs,
          datasets: [{
            label: "Total",
            data: aux0,
            backgroundColor: colores,
            borderWidth: 1
          }, {
            label: "Scotiabank",
            data: aux ,
            backgroundColor: colores,
            borderWidth: 1            
          }
          ]
        },
        options: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: "Scotiabank v/s total",
            position: 'top',
            fontSize: 16,
            padding: 20
          },

          responsive: true,
          display:true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stacked: false
              }
            }]
          }
        }
      });  
  }

  grafico4(data) {
    var labels = Object.keys(data[0]);
    labels.shift();
    
    
    var categs = this.dataSource2.data.map(function(e) {
      return e.nombre_categoria;
    });

    var colores = this.dataSource3.data.map(function(e) {
      var auxcol = '';
      auxcol += parseInt(e.color.substring(1,3), 16).toString()+",";
      auxcol += parseInt(e.color.substring(3,5), 16).toString()+",";
      auxcol += parseInt(e.color.substring(5,7), 16).toString();
      return 'rgba('+auxcol+', 0.5)';
    });

    console.log(categs);
    var datatot = [];
    
    var aux = Object.values(data[5]);
    aux.shift();

    colores = this.llenar_array(colores, categs.length);
    
    var canvas = document.getElementById('grafico4') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'pie',
      data: {
        labels: categs,
        datasets: [{
          label: "Totales",
          data: aux,
          backgroundColor: colores,
          borderWidth: 1
        }]
      },
        options: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: "% Scotiabank",
            position: 'top',
            fontSize: 16,
            padding: 20
          },

          responsive: true,
          display:true,
        }
      });  
  }

}
