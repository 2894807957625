import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { BenefitService } from 'src/app/shared/services/benefit.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PanelcategoryService } from '../../shared/services/panelcategory.service';
import { IPanelcategoryElement } from 'src/app/shared/interfaces/ipanelcategory-element';

import { CompanyService } from '../../shared/services/company.service';
import { ICompanyElement } from 'src/app/shared/interfaces/icompany-element';

import { MarcaService } from '../../shared/services/marca.service';
import { IMarcaElement } from 'src/app/shared/interfaces/imarca-element';


import * as _ from 'lodash';

export interface Type {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-benefit',
  templateUrl: './create-benefit.component.html',
  styleUrls: ['./create-benefit.component.scss']
})
export class CreateBenefitComponent implements OnInit {

  public loading: boolean;
  public subscription: Subscription;
  public submitted: boolean;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Beneficios';
  dataSource = new MatTableDataSource<IPanelcategoryElement>();
  dataSource2 = new MatTableDataSource<ICompanyElement>();
  dataSource3 = new MatTableDataSource<IMarcaElement>();
  public items: any;
 
  public types: any;

 
  constructor(
    public dialogRef: MatDialogRef<CreateBenefitComponent>,
    private notificationService: NotificationsService,
    public benefitService: BenefitService,
    private resourcesService: ResourcesService, 
    public panelcategoryService: PanelcategoryService,
    public companyService: CompanyService,
    public marcaService: MarcaService
  ) {
    this.idComponent = this.benefitService.registerForm.get('id').value;
  }

  ngOnInit() {
    this.reset = this.benefitService.registerForm.get('id').value;

    this.getCategories();
    this.getCompanies();
    this.getMarcas();

  }

  getCategories() {

      this.panelcategoryService.getCategories().subscribe((data: any) => {
        this.dataSource.data = data as IPanelcategoryElement[];
        console.log(this.dataSource.data);
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
  }


  getCompanies() {

      this.companyService.getCompanies().subscribe((data: any) => {
        this.dataSource2.data = data as ICompanyElement[];
        console.log(this.dataSource2.data);
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
  }

  getMarcas() {

      this.marcaService.getMarcas().subscribe((data: any) => {
        this.dataSource3.data = data as IMarcaElement[];
        console.log(this.dataSource3.data);
      }, (error: any) => {
        console.error(error);
      }, () => { this.loading = false; });
  }

  onSubmit() {
    if (this.benefitService.registerForm.valid) {
      if (!this.benefitService.registerForm.get('id').value) {
        this.subscription = this.benefitService.store(this.benefitService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Guardado satisfactoriamente', 'success');
          this.onClose(true);
        }, (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log('Client-side error');
          } else {
            console.log('Server-side error');
          }
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.subscription = this.benefitService.update(this.benefitService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.benefitService.registerForm.controls; }

  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.benefitService.registerForm.controls.image.setValue(inputNode.files[0].name);

    this.resourcesService.upload(inputNode.files[0], 'images', true).subscribe((data: any) => {
      console.log(data);
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  onClose(confirmation: boolean) {
    this.benefitService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }

  onChange($event: any) {
    //this.estado = $event.checked;
  }

}
