import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8'
  })
};

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(
    protected httpClient: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  login(email: string, passwd: string) {
    const params = {username: email, password: passwd};
    return this.httpClient.post<any>('/bff/login', JSON.stringify(params), {observe: 'response'});
  }

  public get loggedIn(): boolean {
    return localStorage.getItem('access_token') !==  null;
  }

  public get decodeToken() {
    return this.jwtHelper.decodeToken(localStorage.getItem('access_token'));
  }

  public get isTokenExpired(): boolean {
    return this.jwtHelper.isTokenExpired(localStorage.getItem('access_token'));
  }
}
