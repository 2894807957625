import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IPopupElement } from '../interfaces/ipopup-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/pop-ups";

@Injectable({
	providedIn: 'root'
})
export class PopupService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getPopups(): Observable<IPopupElement[]> {
		return this.httpClient.get<IPopupElement[]>(`/bff/admin${PATH}`);
	}

	getPopup(id: any): Observable<IPopupElement> {
		return this.httpClient.get<IPopupElement>(`/bff/admin${PATH}/${id}`);
	}

	store(data: any) {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
		.set('nombre', data.nombre)
		.set('activo', data.activo)
		.set('imagen', data.imagen)
		.set('link', data.link)
		.set('id_cupon', data.id_cupon.join(','))
		.set('id_encuesta', data.id_encuesta);

		return this.httpClient.post<any>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
	}

	update(data: any): Observable<IPopupElement[]> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
		.set('nombre', data.nombre)
		.set('activo', data.activo)
		.set('imagen', data.imagen)
		.set('link', data.link)
		.set('id_cupon', data.id_cupon.join(','))
		.set('id_encuesta', data.id_encuesta);

		return this.httpClient.put<IPopupElement[]>(
			`/bff/admin${PATH}/${data.id_pop_up}`,
			body.toString(),
		{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
	}

	delete(id: number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}

}
