import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ResourcesService } from '../../shared/services/resources.service';
import { EmailService } from '../../shared/services/email.service';
import { IEmailElement } from '../../shared/interfaces/iemail-element';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {
	public date_fechaInicio:string;
	public date_fechaFin:string;
	public idComponent: number;
	public nameComponent = 'Email';
	public registerForm: FormGroup;
	public loading: boolean;
	public activo : boolean;
	public submitted: boolean;
	public ckeConfig: any;


	constructor(
		public dialogRef: MatDialogRef<EmailsComponent>,
		private notificationService: NotificationsService,
		public emailService: EmailService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.activo = false;
		this.loading = false;
	}

	ngOnInit() {
		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un sitio */
			this.registerForm = this.formBuilder.group({
				idEmail: null,
				nombre: '',
				asunto: null,
				tipo: null,
				html: null,
				// imagen: '',
				fechaInicio: null,
				fechaFin: null,
				orden: 1,
				estado: [false]
			});
		} else {
			this.idComponent = this.data.idEmail;
			/* Se corrige la ruta de la imagen de imagen para cargarla al formulario */
		//	let imagen = this.data.imagen.split('/');
		//	this.data.imagen = imagen[imagen.length - 1];
			this.date_fechaInicio=this.data.fechaInicio.split(' ');
			this.date_fechaFin=this.data.fechaFin.split(' ');
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				idEmail: this.data.idEmail,
				nombre: this.data.nombre,
				asunto: this.data.asunto,
				tipo: this.data.tipo,
				html: this.data.html,
				// imagen: this.data.imagen,
				fechaInicio: this.date_fechaInicio[0] + 'T' + this.date_fechaInicio[1],
				fechaFin: this.date_fechaFin[0] + 'T' + this.date_fechaFin[1],
				estado: this.data.estado
			});
		}
	}

	onSubmit() {
		if (this.registerForm.valid) {
			if (!this.registerForm.get('idEmail').value) {
				this.emailService.store(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			} else {
				this.emailService.update(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			}
		}
	}

	onFileInput(fileInput: any, $event: any) {
		$event.preventDefault();
		fileInput.click();
	}


  /*


	onFileSelected() {
		const inputNode: any = document.querySelector('#file') as HTMLInputElement;
		this.registerForm.controls.imagen.setValue(inputNode.files[0].name);
		this.resourcesService.upload(inputNode.files[0], 'images').subscribe((data: any) => {
			console.log(data);
		}, (error: any) => {
			console.error(error);
		}, () => {
			this.loading = false;
		});
	}
  */

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

}
