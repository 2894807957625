import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ILikeElement } from 'src/app/shared/interfaces/ilike-element';
import { LikeService } from 'src/app/shared/services/like.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateLikeComponent } from '../create-like/create-like.component';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-like-list',
  templateUrl: './like-list.component.html',
  styleUrls: ['./like-list.component.scss']
})
export class LikeListComponent implements OnInit {

  public displayedColumns: string[] = [
    // 'select',
    'nombre',
    'imagen',
    'estado',
    'actions'
  ];

  loading: boolean;
  dataSource = new MatTableDataSource<ILikeElement>();
  selection = new SelectionModel<ILikeElement>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private likeService: LikeService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getLikes();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

	getLikes(): void {
		this.dataSource.paginator = this.paginator;
		this.likeService.getLikes().subscribe((data: any) => {
			this.dataSource.data = data as ILikeElement[];
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo gustos: ${error.message}`);
		}, () => { this.loading = false; });
	}

  create() {
    this.dialog.open(CreateLikeComponent, {
      width: '60%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getLikes();
      }
    });
  }

  edit(row: any) {
    const img = row.imagen.split('/');
    const data = {
      id: row.id,
      name: row.nombre,
      imagen: img[3],
      status: row.estado
    };
    this.likeService.edit(data);
    this.create();
  }

  delete(id: any) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.likeService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.getLikes(); });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ILikeElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
