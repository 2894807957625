import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { Observable } from 'rxjs';
import { ItemService } from '../../shared/services/item.service';
import { CKEditorConstants } from 'src/app/shared/constants/ckeditor-constants';
import { ICategoryElement } from 'src/app/shared/interfaces/icategory-element';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IItemElement } from 'src/app/shared/interfaces/iitem-element';

export interface Type {
	id: number;
	value: string;
}

@Component({
	selector: 'app-create-category',
	templateUrl: './create-category.component.html',
	styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {

	public loading: boolean;
	public ckeConfig: any;
	public idComponent: number;
	public nameComponent = 'Categoría';
	registerForm: FormGroup;
	public items: IItemElement[];

	types: Type[] = [
		{id: 1, value: 'PRODUCTOS'},
		{id: 2, value: 'RUBROS'}
	];

	constructor(
		public dialogRef: MatDialogRef<CreateCategoryComponent>,
		private notificationService: NotificationsService,
		public categoryService: CategoryService,
		public itemService: ItemService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) private data: ICategoryElement
	) { }

	ngOnInit() {
		this.ckeConfig = CKEditorConstants.ckeConfig;

		if (this.data == null) {
			this.registerForm = this.formBuilder.group({
				id_categoria_producto: null,
				name: ['', [Validators.required, Validators.minLength(3)]],
				terms: ['', [Validators.required, Validators.minLength(3)]],
				descripcion: '',
				image: ['', Validators.required],
				str_index: ['', Validators.required],
				orden: [100, Validators.required],
				color_hover: ['', Validators.required],
				type: [1, [Validators.required]],
				id_item: [1, [Validators.required]],
				nominative: false
			});
		} else {
			this.registerForm = this.formBuilder.group({
				id_categoria_producto: this.data.id_categoria_producto,
				name: [this.data.nombre, [Validators.required, Validators.minLength(3)]],
				terms: [this.data.condiciones_legales, [Validators.required, Validators.minLength(3)]],
				descripcion: this.data.descripcion,
				image: this.data.imagen,
				str_index: this.data.strIndex,
				orden: this.data.orden,
				color_hover: this.data.color_hover,
				type: this.data.tipo,
				id_item: this.data.rubro.id_rubro,
				nominative: this.data.nominativa
			});
		}

		// Rubros
		this.itemService.getItems().subscribe((res) => {
			this.items = res;
		}, (error: any) => {
			console.error(error);
		});
	}

	onSubmit() {
    	if (this.registerForm.valid) {
			this.loading = true;
			let saveCategoryObservable: Observable<ICategoryElement>;
			if (!this.registerForm.get('id_categoria_producto').value) {
				saveCategoryObservable = this.categoryService.store(this.registerForm.value)
			} else {
				saveCategoryObservable = this.categoryService.update(this.registerForm.value)
			}
			saveCategoryObservable.subscribe(() => {
				this.notificationService.success(':: Guardado satisfactoriamente');
				this.onClose(true);
			}, (error) => {
				this.notificationService.warn(`:: Error guardando producto: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}
}

