import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CouponService } from '../../shared/services/coupon.service';
import { NotificationsService } from '../../shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { ICouponElement } from 'src/app/shared/interfaces/icoupon-element';
import { CouponComponent } from '../coupon/coupon.component';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss']
})
export class CouponListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
		'titulo',
		'orden',
		'activo',
		'actions'
	];

	loading:boolean = true;

	dataSource = new MatTableDataSource<ICouponElement>();
	selection = new SelectionModel<ICouponElement>(true, []);

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

	constructor(private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService,
		private couponService:CouponService,
		private dialog: MatDialog) { }

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getCoupons();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getCoupons(): void {
		this.loading = true;
		this.couponService.getCoupons().subscribe((data) => {
			this.dataSource.data = data;
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo los cupones: ${error.error.message}`);
		}).add(() => {
			this.loading = false
		});
	}

	openModal(data?: any): void {
		this.dialog.open(CouponComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getCoupons();
			}
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.couponService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
					this.getCoupons();
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando registro: ${error.error.message}`);
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
