import { Component, OnInit, ViewChild } from '@angular/core';
import { SiteService } from '../../shared/services/site.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SiteComponent } from '../site/site.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ISiteElement } from 'src/app/shared/interfaces/isite-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SubnavbarService } from 'src/app/shared/services/subnavbar.service';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
		'fecha_inicio',
		'fecha_fin',
		'imagen',
		'activo',
		'actions'
	];

	loading: boolean;
	hidden: boolean;
	dataSource = new MatTableDataSource<ISiteElement>();
	selection = new SelectionModel<ISiteElement>(true, []);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private activatedRoute: ActivatedRoute,
		private siteService: SiteService,
		private submenuService: SubnavbarService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		private location: Location,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getSites();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getSites(): void {
		this.loading = true;
		this.activatedRoute.queryParams.subscribe(params => {
			if (params.id_submenu) {
				this.hidden = true;
				this.submenuService.getSubmenuSites(params.id_submenu).subscribe((data) => {
					this.dataSource.data = data;
				}, (error) => {
					this.notificationService.warn(`:: Error obteniendo los sitios: ${error.error.message}`);
				}).add(() => {
					this.loading = false;
				});
			} else {
				this.hidden = false;
				this.siteService.getSites().subscribe((data) => {
					this.dataSource.data = data;
				},(error) => {
					this.notificationService.warn(`:: Error obteniendo los sitios: ${error.error.message}`);
				}).add(() => {
					this.loading = false;
				});
			}
		});
	}

	openModal(data?: any): void {
		this.dialog.open(SiteComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getSites();
			}
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				this.siteService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					console.error(error);
				}).add(() => {
					this.getSites();
				});
			}
		});
	}

	backTo() {
		this.location.back();
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
