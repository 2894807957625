export class DateFormatter {
	
	static format(date: string): string {
		if (!date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/g)) {
			throw 'La fecha no tiene el formato esperado';
		}
		const dateAsArray = date.split('T');
		const dateFormatted = `${dateAsArray[0]} ${dateAsArray[1]}:00`;
		return dateFormatted;
	}

}