import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { ISegmentoElement } from '../interfaces/isegmento-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/segmentos";

@Injectable({
	providedIn: 'root'
})
export class SegmentoService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getSegmentos(): Observable<ISegmentoElement[]> {
		return this.httpClient.get<ISegmentoElement[]>(`/bff/admin${PATH}`);
	}

	store(data: any): Observable<ISegmentoElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('descripcion', data.descripcion)
			.set('codigo', data.codigo);

		return this.httpClient.post<any>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<ISegmentoElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('descripcion', data.descripcion)
			.set('codigo', data.codigo);

		return this.httpClient.put<any>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}

	uploadFile(data: any) {
		const formData: FormData = new FormData();
		formData.append('id_segmento', data.id_segmento);
		formData.append('file', data.fileSource);
		formData.append('overwrite', data.overwrite);
		
		return this.httpClient.post(
			`/bff/admin${PATH}/actualizar`,
			formData);
	}
}
