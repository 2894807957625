import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { InformationService } from 'src/app/shared/services/information.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import * as _ from 'lodash';
import { SubnavbarService } from '../../shared/services/subnavbar.service';

export interface Type {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-information',
  templateUrl: './create-information.component.html',
  styleUrls: ['./create-information.component.scss']
})
export class CreateInformationComponent implements OnInit {

  public loading: boolean;
  public status: boolean;
  public submitted: boolean;
  public ckeConfig: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Information';

  types: Type[] = [
    {id: 1, value: 'TIPO 1'},
    {id: 2, value: 'TIPO 2'}
  ];

  submenus: any;
  aux: any;

  constructor(
    public dialogRef: MatDialogRef<CreateInformationComponent>,
    private notificationService: NotificationsService,
    public informationService: InformationService,
    public subMenuService: SubnavbarService,
    private dialog: MatDialog,
  ) {
    this.status = false;
    this.submitted = false;
    this.loading = false;
    this.idComponent = this.informationService.informationForm.get('id').value;
  }

  ngOnInit() {
    this.reset = this.informationService.informationForm.get('id').value;
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About,Styles,Format,Font,FontSize,Scayt',
      removeDialogTabs: 'link:advanced'
    };
    // Type
    const type = this.types.find(c => c.id === this.informationService.informationForm.get('type').value);
    this.informationService.informationForm.get('type').setValue(type);

    // SubMenu
    this.subMenuService.getSubmenus().subscribe((res) => {
      this.aux = res;
      this.submenus = _.filter(this.aux, (o) => o.id_tipo_submenu === 9);
      const submenu = _.find(this.submenus, (o) => o.id === this.informationService.informationForm.get('id_submenu').value);
      this.informationService.informationForm.get('id_submenu').setValue(submenu);
    }, (error: any) => {
      console.error(error);
    }, () => {
      console.log('Observer got a complete notification');
    });
  }

  get f() { return this.informationService.informationForm.controls; }

  onSubmit() {
    if (this.informationService.informationForm.valid) {
      if (!this.informationService.informationForm.get('id').value) {
        this.informationService.store(this.informationService.informationForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        this.informationService.update(this.informationService.informationForm.value).subscribe((res) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  onChange($event: any) {
    this.status = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.informationService.informationForm.reset();
    this.dialogRef.close(confirmation);
  }

}
