import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { BenefitService } from '../../shared/services/benefit.service';
import { IBenefitElement } from 'src/app/shared/interfaces/ibenefit-element';
import { CreateBenefitComponent } from '../create-benefit/create-benefit.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import readXlsxFile from 'read-excel-file';


@Component({
  selector: 'app-benefit-list',
  templateUrl: './benefit-list.component.html',
  styleUrls: ['./benefit-list.component.scss']
})
export class BenefitListComponent implements OnInit {

  loading: boolean;
  id_alianza : number;
  id_categoria : number;

  dataSource = new MatTableDataSource<IBenefitElement>();
  selection = new SelectionModel<IBenefitElement>(true, []);


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    'id',
    'nombre_alianza',
    'nombre_categoria',
    'nombre_marca',
    'cantidad_beneficios',
    'fecha_periodo',
    'cuotas',
    'descuento',
    'estado',
    //'fecha_fin',
    'actions'
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public benefitService: BenefitService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService,
    activateRoute: ActivatedRoute
  
  ) { 
    this.id_alianza = activateRoute.snapshot.params['id_alianza'];
    this.id_categoria = activateRoute.snapshot.params['id_categoria'];
    this.loading = true;
   }

  ngOnInit() {
  
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      console.log( "Alianza: "+this.id_alianza);
      console.log( "Categoria: "+this.id_categoria);

      this.getCategories();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getCategories() {
    if ((this.id_alianza != undefined) && (this.id_categoria != undefined)){
      this.activeRouter.queryParams.subscribe(params => {
        this.benefitService.getBenefitsfilter(this.id_alianza, this.id_categoria).subscribe((data: any) => {
          this.dataSource.data = data as IBenefitElement[];
        }, (error: any) => {
          console.error(error);
        }, () => { this.loading = false;});
      });
    }
    else{
      this.activeRouter.queryParams.subscribe(params => {
        this.benefitService.getCategories(params.id).subscribe((data: any) => {
          this.dataSource.data = data as IBenefitElement[];
        }, (error: any) => {
          console.error(error);
        }, () => { this.loading = false; });
      });
    }
  }



  onFileInput(fileInput: any, $event: any) {
    $event.preventDefault();
    fileInput.click();
    
  }

 onFileSelected() {
   
   const input2 = <HTMLInputElement>document.getElementById('input');
   const input = <HTMLInputElement>document.getElementById('file');
 
    //input.addEventListener('change', () => {
      readXlsxFile(input.files[0]).then((rows) => {
        console.log(rows);
        rows.shift();
        /* presenta errores de compilacion asi que se deja comentado
        if(rows[5] == 'null')
          rows[5] = '';
        if(rows[6] == 'null')
          rows[6] = 0;
        */
        rows.forEach(item => {
          this.benefitService.store2(item).subscribe((res: any) => {
          }, (err) => {
            if (err.error instanceof Error) {
              console.log('Client-side error');
            } else {
              console.log('Server-side error');
            }
          }, () => {
            console.log('Observer got a complete notification');
          });

          // `rows` is an array of rows
          // each row being an array of cells.
        })
      })
    //})

    this.notificationService.message(':: Guardado satisfactoriamente', 'success');
    //window.location.reload();
}

  create() {
    this.dialog.open(CreateBenefitComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    }).afterClosed().subscribe((res) => {
      if (res) {
        this.loading = true;
        this.getCategories();
      }
    });
  }


  edit(row: any) {
    //alert(row.estado);
    const data = {
      id: row.id,
      nombre_alianza: row.alianza_id,
      nombre_categoria: row.categoria_id,
      nombre_marca: row.marca_id,
      cuotas: row.cuotas,
      descuentos: row.descuento,
      cantidad_beneficios: row.cantidad_beneficios,
      fecha_periodo : row.fecha_periodo,
      //estado: row.estado
    };

    this.benefitService.edit(data);
    this.create();
  }

  delete(id: any) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
      if (res) {
        this.loading = true;
        this.benefitService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.getCategories(); });
      }
    });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }





  cambiar_estado(id, estado){
  
     this.benefitService.update_estado(id, estado).subscribe((res)=>{
      console.log(id +" ->"+estado);
      //window.location.reload();
    });

    //this.benefitService.update_estado(id, estado.toString());
    //alert(id+"   "+estado);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: IBenefitElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
