import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() scotiaID: string;
  @Input() role: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  logout() {
    localStorage.removeItem('access_token');
    this.router.navigate(['login']);
  }
}
