import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { CustomURLEncoder } from '../../urlencoder/component';

const PATH = '/master-configurations';

@Injectable({
	providedIn: 'root'
})
export class MasterConfigurationService {

	constructor(private httpClient: HttpClient) { }

	getMasterConfiguration(id:number) {
		return this.httpClient.get(`/bff/admin${PATH}/${id}`);
	}

	update(data:any) {
		const regex = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/gm;
		let disableExchangeStartDate = data.disable_exchange_start_date;
		if (disableExchangeStartDate.match(regex)) {
			disableExchangeStartDate += ':00';
		}
		let disableExchangeEndDate = data.disable_exchange_end_date;
		if (disableExchangeEndDate.match(regex)) {
			disableExchangeEndDate += ':00';
		}

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('views_enabled', data.views_enabled)
			.set('exchanges_enabled', data.exchanges_enabled)
			.set('disable_exchange_start_date', disableExchangeStartDate)
			.set('disable_exchange_end_date', disableExchangeEndDate)
			.set('max_daily_exchanges', data.max_daily_exchanges)
			.set('max_monthly_exchanges', data.max_monthly_exchanges);
	
		return this.httpClient.put<any>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}
}
