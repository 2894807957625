import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ResourcesService } from '../../shared/services/resources.service';
import { SiteService } from '../../shared/services/site.service';
import { RegionService } from '../../shared/services/region.service';
import { IRegionElement } from '../../shared/interfaces/iregion-element';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
	selector: 'app-site',
	templateUrl: './site.component.html',
	styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {

	idComponent: number;
	nameComponent = 'Sitio';
	registerForm: FormGroup;
	loading: boolean = false;
	regions: IRegionElement[];
	public ckeConfig: any;

	constructor(
		public dialogRef: MatDialogRef<SiteComponent>,
		private notificationService: NotificationsService,
		public siteService: SiteService,
		private resourcesService: ResourcesService,
		private regionService: RegionService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) { }

	ngOnInit() {
		this.ckeConfig = {
			allowedContent: false,
			extraPlugins: 'divarea',
			forcePasteAsPlainText: true,
			toolbarGroups: [
				{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
				{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
				{ name: 'forms', groups: [ 'forms' ] },
				{ name: 'links', groups: [ 'links' ] },
				{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
				{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
				{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
				{ name: 'insert', groups: [ 'insert' ] },
				{ name: 'colors', groups: [ 'colors' ] },
				{ name: 'tools', groups: [ 'tools' ] },
				{ name: 'others', groups: [ 'others' ] },
				{ name: 'about', groups: [ 'about' ] },
				{ name: 'styles', groups: [ 'styles' ] }
			],
			removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
			removeDialogTabs: 'link:advanced'
		};

		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un sitio */
			this.registerForm = this.formBuilder.group({
				$key: null,
				nombre: '',
				direccion: '',
				telefono: '',
				web: '',
				especialidad: '',
				imagen: '',
				fecha_inicio: [null, Validators.required],
				fecha_fin: [null, Validators.required],
				orden: [1, Validators.required],
				destacado: [false],
				activo: [false],
				descripcion:'',
				descripcionPromocion:'',
				condiciones:'',
				detalles:'',
				informacionAdicional:'',
				id_region: [null, Validators.required]
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando un sitio */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = this.data.id_sitio;
			/* Se llena la información del formulario */
			/* Se da formato a las fechas */
			let initDate = this.data.fecha_inicio.split(' ');
			let endDate = this.data.fecha_fin.split(' ');
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				$key: this.data.id_sitio,
				nombre: this.data.nombre,
				direccion: this.data.direccion,
				telefono: this.data.telefono,
				web: this.data.web,
				especialidad: this.data.especialidad,
				imagen: this.data.imagen,
				destacado:this.data.destacado,
				fecha_inicio: [initDate[0] + 'T' + initDate[1], Validators.required],
				fecha_fin: [endDate[0] + 'T' + endDate[1], Validators.required],
				orden: [this.data.orden, Validators.required],
				activo: this.data.activo,
				descripcion: this.data.descripcion,
				descripcionPromocion: this.data.descripcionPromocion,
				condiciones: this.data.condiciones,
				detalles: this.data.detalles,
				informacionAdicional: this.data.informacionAdicional,
				id_region: [this.data.id_region, Validators.required]
			});
		}
		// Regions
		this.regionService.getRegions().subscribe((res) => {
			this.regions = res;
		}, (error: any) => {
			console.error(error);
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('$key').value) {
				this.siteService.store(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					this.notificationService.warn(`:: Error creando sitio: ${error.message}`);
				}).add(() => {
					this.loading = false;
				});
			} else {
				this.siteService.update(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error actualizando sitio: ${error.message}`);
				}).add(() => {
					this.loading = false;
				})
			}
		}
	}


	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}
}
