import { Component, Inject, OnInit, Optional } from '@angular/core';
import { TagsService } from 'src/app/shared/services/tags.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { IIconElement } from 'src/app/shared/interfaces/iicon-element';
import * as _ from 'lodash';
import { ITagElement } from 'src/app/shared/interfaces/itag-element';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
@Component({
	selector: 'app-create-tag',
	templateUrl: './create-tag.component.html',
	styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent implements OnInit {

	registerForm: FormGroup;
	loading: boolean = false;

	idComponent: number;
	nameComponent = 'Etiqueta';

	icons: IIconElement[] = [];
	loadingIcons: boolean = true;

	constructor(
		public tagService: TagsService,
		public dialogRef: MatDialogRef<CreateTagComponent>,
		private notificationService: NotificationsService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: ITagElement
	) {	}

	ngOnInit() {
		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando una etiqueta */
			this.registerForm = this.formBuilder.group({
				id_tag_producto: null,
				nombre: ['', [Validators.required, Validators.minLength(3)]],
				descripcion: ['', [Validators.required, Validators.minLength(3)]],
				icono: ['', [Validators.required]],
				estado: [true]
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando una etiqueta */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = this.data.id_tag_producto;
			/* Se llena la información del formulario */
			this.registerForm = this.formBuilder.group({
				id_tag_producto: this.idComponent,
				nombre: [this.data.nombre, [Validators.required, Validators.minLength(3)]],
				descripcion: [this.data.descripcion, [Validators.required, Validators.minLength(3)]],
				icono: [this.data.icono.replace(' fa-2x', ''), [Validators.required]],
				estado: [this.data.estado]
			});
		}

		// Tags
		this.tagService.getIcons().subscribe((res) => {
			this.loadingIcons = false;
			this.icons = res;
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo íconos: ${error.message}`);
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			let saveTagObservable: Observable<ITagElement>;
			if (!this.registerForm.get('id_tag_producto').value) {
				saveTagObservable = this.tagService.store(this.registerForm.value);
			} else {
				saveTagObservable = this.tagService.update(this.registerForm.value);
			}
			saveTagObservable.subscribe(() => {
				this.notificationService.success(':: Guardado satisfactoriamente');
				this.onClose(true);
			}, (error) => {
				this.notificationService.warn(`:: Error guardando etiqueta: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

}
