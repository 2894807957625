import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HistoryService } from '../../shared/services/history.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class HistoryticketListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'codigo',
    'empresa',
    'tipoTicket',
    'valorPuntos',
    'fechaQuemado',
    'fechaExpiracionTicket',
    'idcliente',
    'rut'
  ];

  constructor(
    protected historyService: HistoryService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getHistory(value) {
    this.historyService.getHistory2(value).subscribe((data: any) => {
      this.dataSource.data = data as any[];
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  getExp(value) {
    this.historyService.getHistory2(value).subscribe((data: any) => {
      //console.log(data as any[]);
      var readyToExport = data as any[];
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(readyToExport);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'historico'); 
    XLSX.writeFile(workBook, 'Historico '+value+'.xlsx'); // initiate a file download in browser


    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  buscar(el) {
    console.log(el.value);
    this.getHistory(el.value);
   

  }

  exportar(el) {
    console.log(el.value);
    this.getExp(el.value);
   

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_usuario_cms + 1}`;
  }

}
