import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { IHighlightedBenefitElement } from 'src/app/shared/interfaces/ihighlighted-benefit-element';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HighlightedBenefitService } from 'src/app/shared/services/highlighted-benefit.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { HighlightedBenefitComponent } from '../highlighted-benefit/highlighted-benefit.component';

@Component({
  selector: 'app-highlighted-benefit-list',
  templateUrl: './highlighted-benefit-list.component.html',
  styleUrls: ['./highlighted-benefit-list.component.scss']
})
export class HighlightedBenefitListComponent implements OnInit {

	public displayedColumns: string[] = [
		'titulo',
		'subtitulo',
		'imagen',
		'orden',
		'activo',
		'actions'
	];

	loading: boolean = true;

	dataSource = new MatTableDataSource<IHighlightedBenefitElement>();
	selection = new SelectionModel<IHighlightedBenefitElement>(true, []);

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	constructor(
		private highlightedBenefitService: HighlightedBenefitService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService) { }

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getHighlightedBenefits();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getHighlightedBenefits(): void {
		this.loading = true;
		this.highlightedBenefitService.getHighlightedBenefits().subscribe((data) => {
			this.dataSource.data = data;
		},(error) => {
			this.notificationService.warn(`:: Error obteniendo los beneficios destacados: ${error.error.message}`);
		}, () => {
			this.loading = false;
		});
	}

	openModal(data?: any): void {
		this.dialog.open(HighlightedBenefitComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getHighlightedBenefits();
			}
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.highlightedBenefitService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando registro: ${error.error.message}`);
				}).add(() => {
					this.getHighlightedBenefits();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
