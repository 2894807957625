import { Component, ViewChild, OnInit } from '@angular/core';
import { PollService } from '../../shared/services/poll.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { PollComponent } from '../poll/poll.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IPollElement } from 'src/app/shared/interfaces/ipoll-element';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-poll-list',
  templateUrl: './poll-list.component.html',
  styleUrls: ['./poll-list.component.scss']
})
export class PollListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
		'fecha_inicio',
		'fecha_fin',
		'nro_preguntas',
		'activo',
		'actions'
	];
	
	loading: boolean = true;
	dataSource = new MatTableDataSource<IPollElement>();
	selection = new SelectionModel<IPollElement>(true, []);

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

	constructor(
		private pollService: PollService,
		private dialog: MatDialog,
    	private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService) { 
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getPolls();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getPolls(): void {
		this.loading = true;
		this.pollService.getPolls().subscribe((data: any) => {
			this.dataSource.data = data as IPollElement[];
		},(error) => {
			this.notificationService.warn(`:: Error obteniendo encuestas: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(data?: any): void {
		this.dialog.open(PollComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getPolls();
			}
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.pollService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando encuesta: ${error.error.message}`);
				}).add(() => {
					this.getPolls();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
