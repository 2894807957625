import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomURLEncoder } from 'src/app/urlencoder/component';
import { ICouponElement } from '../interfaces/icoupon-element';

const PATH = '/cupones';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

	constructor(private httpClient: HttpClient) { }

	getCoupons(): Observable<ICouponElement[]> {
		return this.httpClient.get<ICouponElement[]>(`/bff/admin${PATH}`);
	}

	getCoupon(id:number): Observable<ICouponElement> {
		return this.httpClient.get<ICouponElement>(`/bff/admin${PATH}/${id}`);
	}

	store(data: any): Observable<ICouponElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('titulo', data.titulo)
			.set('subtitulo', data.subtitulo)
			.set('imagen', data.imagen)
			.set('orden', data.orden)
			.set('descripcion', data.descripcion)
			.set('texto_btn', 'texto_btn')
			.set('tipo', data.tipo)
			.set('link', data.link)
			.set('blank', data.blank)
			.set('id_producto', !data.id_producto ? '' : data.id_producto)
			.set('activo', data.activo);

		return this.httpClient.post<ICouponElement>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<ICouponElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('titulo', data.titulo)
			.set('subtitulo', data.subtitulo)
			.set('imagen', data.imagen)
			.set('orden', data.orden)
			.set('descripcion', data.descripcion)
			.set('texto_btn', 'texto_btn')
			.set('tipo', data.tipo)
			.set('link', data.link)
			.set('blank', data.blank)
			.set('id_producto', !data.id_producto ? '' : data.id_producto)
			.set('activo', data.activo);

		return this.httpClient.put<ICouponElement>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}
}
