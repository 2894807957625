
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NoticiasService } from 'src/app/shared/services/noticias.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, Subject } from 'rxjs';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TagsService } from 'src/app/shared/services/tags.service';
import * as _ from 'lodash';

/* Interfaz para imagenes Miniaturas */
interface CardImages {
valor: string;
valorVista: string;
}

@Component({
  selector: 'app-create-noticia',
  templateUrl: './create-noticias.component.html',
  styleUrls: ['./create-noticias.component.scss']
})
export class CreateNoticiasComponent implements OnInit {
  public loading: boolean;
  public estado: boolean;
  public submitted: boolean;
  public selected: boolean;
  public idComponent: number;
  public folder: string;
  public ckeConfig: any;
  public nameComponent = 'Noticia';
  public subscription: Subscription;
  public tags$: Observable<any>;
  public selectedTag: any;
  public tagInput$ = new Subject<string>();
  public resource$: Observable<any>;
  public selectedResource: any;
  public resourceInput$ = new Subject<string>();
  public noticiaForm : FormGroup;
  public initialValues : any;




  public cardImages: CardImages[] = [
    {valor: 'miniatura1.png', valorVista: 'Miniatura 1'},
    {valor: 'miniatura2.png', valorVista: 'Miniatura 2'},
    {valor: 'miniatura3.png', valorVista: 'Miniatura 3'},
    {valor: 'miniatura4.png', valorVista: 'Miniatura 4'}
  ];


  constructor(
    public tagsService : TagsService,
    public dialogRef: MatDialogRef<CreateNoticiasComponent>,
    private notificationService: NotificationsService,
    public noticiasService: NoticiasService,
    public resourcesService: ResourcesService,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.noticiaForm = this.formBuilder.group({
          id: null,
          titulo: ['', [Validators.required, Validators.minLength(3)]],
          subtitulo: [''],
          cuerpo: [''],
          pie: [''],
          estado: [true],
          orden: 0,
          tipo: [false],
          rutaMiniatura : ['', [Validators.required]],
          tags: Array(),
          recursos: Array(),
          rutaRecursoNoticia: [''],
          rutaImagenNoticia: ['']
        });
    this.estado = true;
    this.idComponent = this.noticiaForm.get('id').value;

    }

  ngOnInit() {
        if(this.data!=null){
          const noticiaExistente={
            id: this.data.id,
            titulo: this.data.titulo,
            subtitulo: this.data.subtitulo,
            cuerpo: this.data.cuerpo,
            pie: this.data.pie,
            orden: this.data.orden,
            estado: this.data.estado,
            tipo: this.data.tipo,
            rutaRecursoNoticia: this.data.rutaRecursoNoticia,
            rutaImagenNoticia: this.data.rutaImagenNoticia,
            rutaMiniatura:this.data.rutaMiniatura,
            tags: (Array.isArray(this.data.tags)) ? this.data.tags : Array(),
            recursos: (Array.isArray(this.data.resources)) ? this.data.resources : Array()
          }
          if(this.data.tipo==1){
            this.tipoNoticia(true);  
          }else{
            this.tipoNoticia(false);  
          }
          this.noticiaForm.setValue(noticiaExistente);
          }


    /* CKE CONFIG */
    this.ckeConfig = {
      allowedContent: true,
      extraAllowedContent :'img[src,width,height,alt,title]',
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] },
        { name: 'styles', groups: [ 'styles' ] }
      ],
      removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About,Styles,Format,Font,FontSize,Scayt',
      removeDialogTabs: 'link:advanced'
    };

    // TAGS
    this.tags$ = this.tagsService.getTags();
    this.selectedTag = this.noticiaForm.get('tags').value;
    // RECURSOS
    this.resource$ = this.resourcesService.getResourcesNoticias();
    this.selectedResource = this.noticiaForm.get('recursos').value;

  } // cierra ngOnInit



/*Crea la noticia */
  onSubmit() {
    if (this.noticiaForm.valid) {
      if (!this.noticiaForm.get('id').value) {
        this.noticiasService.create(this.noticiaForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Enviado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      } else {
        /* acá actualiza una noticia */
        this.noticiasService.update(this.noticiaForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          console.log('Observer got a complete notification');
        });
      }
    }
  }

  get f() { return this.noticiaForm.controls; }


  onChange($event: any) {
    this.estado = $event.checked;
  }


  onClose(confirmation: boolean) {
    this.noticiaForm.reset();
    this.dialogRef.close(confirmation);
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public tipoNoticia(event){
    console.log(event);
    console.log("hola function"); 
    if(!event){
        this.noticiaForm.get('rutaImagenNoticia').clearValidators();
        this.noticiaForm.get('rutaImagenNoticia').updateValueAndValidity();
        this.noticiaForm.get('rutaImagenNoticia').disable();
        console.log("hola false");       
    }else{
        this.noticiaForm.get('rutaImagenNoticia').enable();
        this.noticiaForm.get('rutaImagenNoticia').setValidators([Validators.required])
        this.noticiaForm.get('rutaImagenNoticia').updateValueAndValidity();
        console.log("hola true");     
    }
    this.onChange(event);
 }
}
