import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { EventoService } from 'src/app/shared/services/evento.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _moment from 'moment';
import 'moment/locale/es';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { IEventoElement } from 'src/app/shared/interfaces/ieventos-element';
import { IRegionesElement } from 'src/app/shared/interfaces/iregiones-element';
import { ICommunesElement } from 'src/app/shared/interfaces/icommunes-element';
import { ResourcesService } from 'src/app/shared/services/resources.service';

interface datosInterface {
  valor: number;
  valorVista: string;
  }
@Component({
  selector: 'app-create-evento',
  templateUrl: './create-evento.component.html',
  styleUrls: ['./create-evento.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class CreateEventoComponent implements OnInit, OnDestroy {
    public loading: boolean;
    public estado: boolean;
    public submitted: boolean;
    public selected: boolean;
    public idComponent: number;
    public eventoForm: FormGroup;
    public nameComponent = 'Evento';
    public subscription: Subscription;
    public initialValues : any;
    public regionDefault: string ='13';
    public regiones: IRegionesElement[] = [];
    public comunas: ICommunesElement[] = [];
    public loadingRegiones: boolean = true;
    public estados: datosInterface[] = [
      {valor: 1, valorVista: 'Activo'},
      {valor: 2, valorVista: 'Inactivo'}
    ];
    public tipos: datosInterface[] = [
      {valor: 1, valorVista: 'Concierto'},
      {valor: 2, valorVista: 'Seminario'}
    ];
    saving: boolean = false;

    constructor(
      public dialogRef: MatDialogRef<CreateEventoComponent>,
      public resourcesService: ResourcesService,
      public notificationService: NotificationsService,
      public eventoService: EventoService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder
    ) {
      this.eventoForm = this.formBuilder.group({
        idEvento: null,
        fechaCreacion: '',
        fechaModificacion: '',
        fechaInicio: [null, Validators.required],
        fechaFin: [null, Validators.required],
        titulo: '',
        descripcion: '',
        imagen: '',
        background: '',
        region: this.regionDefault,
        tipo: 1,
        video: '',
        comuna: '',
        direccion: '',
        datoAdicional: '',
        datoAdicionalDos: '',
        estado: 1
      });
      this.estado = true;
      this.idComponent = this.eventoForm.get('idEvento').value;
      this.initialValues = this.eventoForm.value;
    
     }
  
    ngOnInit() {
      this.resourcesService.getRegiones().subscribe((res) => {
        this.loadingRegiones = false;
        /* Traemos las regiones del unico pais que tiene el json hasta el momento. Chile */
        this.regiones = res.regions;
        if(this.data!=null){
            this.seleccionarComunas(this.data.region.idRegion.toString(), false);
        }else{
            this.seleccionarComunas(this.regionDefault, true);
        }
      }, (error: any) => {
        this.notificationService.warn(`:: Error obteniendo Paises: ${error.message}`);
      });
      /* Asigna valores al form de un registro existente */
      if (this.data != null) {
        const eventoExistente = {
          idEvento: this.data.idEvento,
          fechaCreacion: this.data.fechaCreacion,
          fechaModificacion: this.data.fechaModificacion,
          fechaInicio: this.data.fechaInicio,
          fechaFin: this.data.fechaFin,
          titulo: this.data.titulo,
          descripcion: this.data.descripcion,
          imagen: this.data.imagen,
          background: this.data.background,
          region: this.data.region.idRegion.toString(),
          tipo: this.data.tipo,
          video: this.data.video,
          comuna: this.data.comuna,
          direccion: this.data.direccion,
          datoAdicional: this.data.datoAdicional,
          datoAdicionalDos: this.data.datoAdicionalDos,
          estado: this.data.estado,
        };
      this.eventoForm.setValue(eventoExistente);

    }
    this.eventoForm.get('fechaModificacion').disable(); 
    this.eventoForm.get('fechaCreacion').disable();

  } // cierra ngOnInit
  
  /*Crea el user */
    onSubmit() {
      if (this.eventoForm.valid) {
        if (this.eventoForm.get('idEvento').value) {
          this.eventoService.update(this.eventoForm.value).subscribe((res: any) => {
            this.submitted = true;
            this.notificationService.message(':: Enviado satisfactoriamente', 'success');
            this.onClose(true);
          }, (error: any) => {
            console.error(error);
          }, () => {
            console.log('Observer got a complete notification');
          });
        }else{
          this.eventoService.save(this.eventoForm.value).subscribe((res: any) => {
            this.submitted = true;
            this.notificationService.message(':: Enviado satisfactoriamente', 'success');
            this.onClose(true);
          }, (error: any) => {
            console.error(error);
          }, () => {
            console.log('Observer got a complete notification');
          });
        }
      }
    }

    
  
    get f() { return this.eventoForm.controls; }
  
    seleccionarComunas(valorSeleccionado: any, select:boolean): void {
      for(const region of this.regiones){
        if(region.number == valorSeleccionado){
          this.comunas = region.communes;
          if(select){

            this.eventoForm.get('comuna').setValue(this.comunas[0].name);  
          } 
        }
      } 
    }
	
  
    onChange($event: any) {
      this.estado = $event.checked;
    }
  
    onClose(confirmation: boolean) {
      this.eventoForm.reset();
      this.dialogRef.close(confirmation);
    }
  
  
    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }


}
