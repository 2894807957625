import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-form-toolbar',
  templateUrl: './form-toolbar.component.html',
  styleUrls: ['./form-toolbar.component.scss']
})
export class FormToolbarComponent implements OnInit {

	@Input() id: number;
	@Input() name: string;
	@Output() closed = new EventEmitter<boolean>();
	text: string;

  constructor() { }

	ngOnInit() {
		this.text = this.id ? `Modificar ${this.name}` : `Crear ${this.name}`;
	}

	closeWindow() {
		this.closed.emit(false);
	}

}
