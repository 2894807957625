import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubmenuInformationService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      image: ['', [Validators.required]],
      title: ['', [Validators.required, Validators.minLength(3)]],
      subtitle: ['', [Validators.required, Validators.minLength(3)]],
      description: ['', [Validators.required, Validators.minLength(3)]],
      link: ['', [Validators.required, Validators.minLength(3)]],
      activated: [false],
      logo: ['', [Validators.required, Validators.minLength(3)]],
      id_submenu: [''],
      str_index: ['', [Validators.required, Validators.minLength(3)]],
      link_text: ['', [Validators.required, Validators.minLength(3)]],
      terms: ['', [Validators.required, Validators.minLength(3)]]
    });
  }

  store(data: any) {
    console.log(data);
    const body = new HttpParams()
      .set('id_submenu', data.id_submenu)
      .set('titulo', data.title)
      .set('subtitulo', data.subtitle)
      .set('descripcion', data.description)
      .set('condiciones', data.terms)
      .set('link', data.link)
      .set('texto_link', data.link_text)
      .set('submenu_strindex', data.str_index)
      .set('imagen', data.image)
      .set('imagen_logo', data.logo)
      .set('estado', (data.activated) ? '1' : '0');

    return this.httpClient.post<any>(
      '/bff/admin/scinformacionsubmenu',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }

  update(data: any) {
    // console.log(data);
    const body = new HttpParams()
      .set('id_submenu', data.id_submenu)
      .set('titulo', data.title)
      .set('subtitulo', data.subtitle)
      .set('descripcion', data.description)
      .set('condiciones', data.terms)
      .set('link', data.link)
      .set('texto_link', data.link_text)
      .set('submenu_strindex', data.str_index)
      .set('imagen',  data.image)
      .set('imagen_logo',  data.logo)
      .set('estado', (data.activated) ? '1' : '0');

    return this.httpClient.put<any>(
      '/bff/admin/scinformacionsubmenu/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
    );
  }

  edit(data: any) {
    console.log(data);
    this.registerForm.setValue(data);
  }

  // getInformations(id?: number) {
  //   if (!id) {
  //     return this.httpClient.get('/bff/admin/scinformacionsubmenu');
  //   } else {
  //     return this.httpClient.get('/bff/admin/scinformacionsubmenu/' + id);
  //   }
  // }

}
