import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {CustomURLEncoder} from '../../urlencoder/component';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id_usuario_cms: null,
      uid: ['', [Validators.required, Validators.minLength(8)]],
      perfil: ['', [Validators.required]],
      estado: [false]
    });
  }

  getUsers() {
    return this.httpClient.get('/bff/admin/usuarios_cms');
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('uid', data.uid)
      .set('id_perfil', data.perfil.id_perfil)
      .set('estado', data.estado);

    return this.httpClient.post<any>(
      '/bff/admin/usuarios_cms',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('uid', data.uid)
      .set('id_perfil', data.perfil.id_perfil)
      .set('estado', data.estado);

    return this.httpClient.put<any>(
      '/bff/admin/usuarios_cms/' + data.id_usuario_cms,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  delete(id: number) {
    return this.httpClient.delete('/bff/admin/usuarios_cms/' + id);
  }
}
