import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {CustomURLEncoder} from '../../urlencoder/component';
import * as _moment from 'moment';
import 'moment/locale/es';
const moment = _moment;
moment.locale('es');

//const url = 'api_panel'
const url = 'http://68.183.111.73/api';

@Injectable({
  providedIn: 'root'
})
export class BenefitService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      nombre_alianza: ['', Validators.required],
      nombre_categoria: ['', Validators.required],
      nombre_marca: ['', [Validators.required]],
      cantidad_beneficios: ['', [Validators.required]],
      fecha_periodo: ['', [Validators.required]],
      cuotas: null,
      descuentos:null,
      //estado: null,
      //fecha_creacion: ['', [Validators.required]],
      //fecha_fin: ['', [Validators.required]]
    });
  }

  getCategories(id?: number): Observable<any> {
    if (!id) {
      return this.httpClient.get<any[]>(url+'/beneficio');
    } else {
      return this.httpClient.get<any[]>(url+'/beneficio/' + id);
    }
  }

  getBenefitsfilter(id_alianza?: number, id_categoria?: number): Observable<any> {
      return this.httpClient.get<any[]>(url+'/beneficio/' + id_alianza+ '/' + id_categoria);
  }

  getTotals(): Observable<any>{
    return this.httpClient.get<any[]>(url+'/monitor');
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  
  update_estado(id: number, estado: string){
     return this.httpClient.get<any[]>(url+'/beneficio_estado/'+id+'/'+estado);
    //console.log(url+'/beneficio_estado/'+id+'/'+estado);

  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre_empresa', data.nombre_alianza)
      .set('nombre_categoria', data.nombre_categoria)
      .set('nombre_marca', data.nombre_marca)
      .set('cantidad_beneficios', data.cantidad_beneficios)
      .set('periodo', data.fecha_periodo)
      .set('cuotas', data.cuotas)
      .set('descuentos', data.descuentos)
      //.set('estado', data.estado)
      
    return this.httpClient.post<any>(
      url+'/beneficio',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }


  store2(data: any) {
    if(!data[4])
      data[4] = moment().format("YYYY-MM-DD");
    else
      data[4] = moment(data[4]).format("YYYY-MM-DD");

    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre_empresa', data[0])
      .set('nombre_categoria', data[1])
      .set('nombre_marca', data[3])
      .set('cantidad_beneficios', data[2])
      .set('periodo', data[4])
      .set('cuotas', data[5])
      .set('descuentos', data[6])
      
    return this.httpClient.post<any>(
      url+'/beneficio',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }
  update(data: any) {
    
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('id', data.id)
      .set('nombre_empresa', data.nombre_alianza)
      .set('nombre_categoria', data.nombre_categoria)
      .set('nombre_marca', data.nombre_marca)
      .set('cantidad_beneficios', data.cantidad_beneficios)
      .set('periodo', data.fecha_periodo)
      .set('cuotas', data.cuotas)
      .set('descuentos', data.descuentos)
      //.set('estado', data.estado)

    return this.httpClient.put<any>(
      url+'/beneficio/'+data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  delete(id: number) {
    return this.httpClient.delete(url+'/beneficio/' + id);
  }
}
