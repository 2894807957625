import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { PollService } from '../../shared/services/poll.service';
import { ResourcesService } from '../../shared/services/resources.service';
import { IQuestionTypeElement } from '../../shared/interfaces/iquestion-type-element';
import { QuestionsTypeService } from '../../shared/services/questions-type.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

/* Interfaz para tipos de inputs */
interface TiposInput {
	valor: string;
	valorVista: string;
}

@Component({
	selector: 'app-poll',
	templateUrl: './poll.component.html',
	styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {

	idComponent: number;
	nameComponent = 'Encuesta';
	registerForm: FormGroup;
	loading: boolean = false;
	submitted: boolean;
	questions_types: IQuestionTypeElement[];


	public tiposInputs: TiposInput[] = [
		{valor: 'text', valorVista: 'Texto'},
		{valor: 'number', valorVista: 'Numeros'},
		{valor: 'email', valorVista: 'Correo'},
		{valor: 'password', valorVista: 'Contraseña'}
	  ];

	constructor(
		public dialogRef: MatDialogRef<PollComponent>,
		private notificationService: NotificationsService,
		public pollService: PollService,
		private resourcesService: ResourcesService,
		private questionsTypeService: QuestionsTypeService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {	}

	ngOnInit() {
		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando una encuesta */
			this.registerForm = this.formBuilder.group({
				$key: null,
				nombre: '',
				fecha_inicio: null,
				fecha_fin: null,
				imagen: '',
				activo: [false],
				orden_aleatorio: [false],
				mostrar_solo: 0,
				preguntas: this.formBuilder.array([])
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando una encuesta */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = this.data.id_encuesta;
			/* Se llena la información del formulario */
			let formArray = new FormArray([]);
			let array = this.data.preguntas_encuesta;
			if (array instanceof Array) {
				array.forEach(element => {
					/* Se llena la información de la preguntas */
					let formGroup = this.formBuilder.group({
						pregunta: element.pregunta,
						placeholder: element.placeholder,
						id_tipo_pregunta_encuesta: element.id_tipo_pregunta_encuesta,
						opciones: [element.opciones],
						clase: element.clase,
						tipo_input: element.tipo_input
					});
					formArray.push(formGroup);
				});
			}
			/* Se da formato a las fechas */
			let initDate = this.data.fecha_inicio.split(' ');
			let endDate = this.data.fecha_fin.split(' ');
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				$key: this.data.id_encuesta,
				nombre: this.data.nombre,
				fecha_inicio: initDate[0] + 'T' + initDate[1],
				fecha_fin: endDate[0] + 'T' + endDate[1],
				imagen: this.data.imagen,
				activo: this.data.activo,
				orden_aleatorio: this.data.orden_aleatorio,
				mostrar_solo: this.data.mostrar_solo,
				preguntas: formArray
			});
		}
		// Questions Types
		this.questionsTypeService.getQuestionsTypes().subscribe((res) => {
			this.questions_types = res;
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo tipos de preguntas: ${error.error.message}`);
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('$key').value) {
				this.pollService.store(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.success(':: Enviado satisfactoriamente');
					this.onClose(true);
				}, (error: any) => {
					this.notificationService.warn(`:: Error creando encuesta: ${error.error.message}`);
				}).add(() => {
					this.loading = false;
				});
			} else {
				this.pollService.update(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.success(':: Actualizado satisfactoriamente');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error actualizando encuesta: ${error.error.message}`);
				}).add(() => {
					this.loading = false;
				});
			}
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

	addQuestion(): void {
		(<FormArray> this.registerForm.get('preguntas')).push(this.addQuestionFormGroup());
	}

	deleteQuestion(i: number): void {
		(<FormArray> this.registerForm.get('preguntas')).removeAt(i);
	}

	addQuestionFormGroup(): FormGroup {
		return this.formBuilder.group({
			pregunta: '',
			placeholder: '',
			id_tipo_pregunta_encuesta: 1,
			opciones: [],
			clase: 0,
			tipo_input: 'text'
		});
	}

}