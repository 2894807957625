import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { BannerService } from '../../shared/services/banner.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SegmentoService } from 'src/app/shared/services/segmento.service';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';
import { CSegmentsConstants } from 'src/app/shared/constants/csegments-constants';
import { ISegmentTypeElement } from 'src/app/shared/interfaces/isegment-type-element';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
	providers: [
		// `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
		// `MatMomentDateModule` in your applications root module. We provide it at the component level
		// here, due to limitations of our example generation script.
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	]
})
export class BannerComponent implements OnInit {

	loading: boolean = false;
	idComponent: number;
	public ckeConfig: any;
	nameComponent = 'Banner';
	registerForm: FormGroup;

	tiposPerfilamientos: ISegmentTypeElement[] = CSegmentsConstants.TiposPerfilamientos;
	segmentos: ISegmentoElement[] = [];
	loadingSegmentos: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<BannerComponent>,
		private notificationService: NotificationsService,
		public bannerService: BannerService,
		private formBuilder: FormBuilder,
		private segmentosService : SegmentoService,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {	}

	ngOnInit() {
		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un banner */
			this.registerForm = this.formBuilder.group({
				$key: null,
				name: ['', [Validators.required, Validators.minLength(3)]],
				ruta: '',
				url: '',
				tipo: 1,
				order: [1, Validators.required],
				date_init: [null, Validators.required],
				date_end: [null, Validators.required],
				status: [false],
				blank: [false],
				responsive: [false],
				html: '',
				tipo_perfilamiento: 1,
				id_segmento: [[]]
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando un banner */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = this.data.id_banner;
			/* Se da formato a las fechas */
			const initDate = this.data.fecha_inicio.split(' ');
			const endDate = this.data.fecha_fin.split(' ');
			/* Se llena la información de los segmentos */
			let selectedSegmentos:number[] = [];
			let segmentosArray = this.data.segmentos;
			if (segmentosArray instanceof Array) {
				segmentosArray.forEach(element => {
					selectedSegmentos.push(element.id_segmento);
				});
			}
			/* Se llena la información del formulario */
			this.registerForm = this.formBuilder.group({
				$key: this.idComponent,
				name: [this.data.nombre, [Validators.required, Validators.minLength(3)]],
				ruta: this.data.ruta,
				url: this.data.url,
				tipo: this.data.tipo,
				order: [this.data.orden, Validators.required],
				date_init: [initDate[0] + 'T' + initDate[1], Validators.required],
				date_end: [endDate[0] + 'T' + endDate[1], Validators.required],
				status: this.data.estado,
				blank: this.data.blank,
				responsive: this.data.responsive,
				html: this.data.html,
				tipo_perfilamiento: this.data.tipo_perfilamiento,
				id_segmento: [selectedSegmentos]
			});
		}
		this.segmentosService.getSegmentos().subscribe((res) => {
			this.loadingSegmentos = false;
			this.segmentos = res;
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('$key').value) {
				this.bannerService.store(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					this.notificationService.warn(`:: Error creando banner: ${error.message}`);
				}).add(() => {
					this.loading = false;
				});
			} else {
				this.bannerService.update(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error actualizando banner: ${error.message}`);
				}).add(() => {
					this.loading = false;
				})
			}
		}
	}


	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}
}