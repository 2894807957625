import { Component, OnInit, ViewChild } from '@angular/core';
import { SegmentoService } from '../../shared/services/segmento.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SegmentoComponent } from '../segmento/segmento.component';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { UploadSegmentoComponent } from '../upload-segmento/upload-segmento.component';

@Component({
  selector: 'app-segmento-list',
  templateUrl: './segmento-list.component.html',
  styleUrls: ['./segmento-list.component.scss']
})
export class SegmentoListComponent implements OnInit {

	public displayedColumns: string[] = [
		'nombre',
		'descripcion',
		'codigo',
		'actions'
	];

	loading: boolean;
	hidden: boolean;
	dataSource = new MatTableDataSource<ISegmentoElement>();
	selection = new SelectionModel<ISegmentoElement>(true, []);


	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

	constructor(
		private segmentoService: SegmentoService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getSegmentos();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getSegmentos() {
		this.segmentoService.getSegmentos().subscribe((data: any) => {
			this.dataSource.data = data as any[];
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo segmentos: ${error.message}`);
		}, () => { this.loading = false; });
	}

	openModal(data?: ISegmentoElement): void {
		this.dialog.open(SegmentoComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getSegmentos();
			}
		});
	}

	uploadFile(data: ISegmentoElement) {
		this.dialog.open(UploadSegmentoComponent, {
			width: '60%',
			autoFocus: true,
			disableClose: true,
			data: data
		});
	}

	delete(id:number): void {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.loading = true;
				this.segmentoService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					console.error(error);
				}).add(() => {
					this.getSegmentos();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string): void {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
