import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { ISiteElement } from '../interfaces/isite-element';

import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/sitios";

@Injectable({
	providedIn: 'root'
})
export class SiteService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getSites(): Observable<ISiteElement[]> {
		return this.httpClient.get<ISiteElement[]>(`/bff/admin${PATH}`);
	}

	store(data: any): Observable<ISiteElement> {
		const initDate = moment(data.fecha_inicio);
		const endDate = moment(data.fecha_fin);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('direccion', data.direccion)
			.set('telefono', data.telefono)
			.set('web', data.web)
			.set('especialidad', data.especialidad)
			.set('imagen', data.imagen)
			.set('destacado', (data.destacado) ? '1' : '0')
			.set('id_region', data.id_region)
			.set('descripcion', data.descripcion)
			.set('descripcionPromocion', data.descripcionPromocion)
			.set('detalles', data.detalles)
			.set('informacionAdicional', data.informacionAdicional)
			.set('condiciones', data.condiciones)
			.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('orden', data.orden)
			.set('activo', data.activo);

		return this.httpClient.post<any>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<ISiteElement> {
		const initDate = moment(data.fecha_inicio);
		const endDate = moment(data.fecha_fin);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('direccion', data.direccion)
			.set('telefono', data.telefono)
			.set('web', data.web)
			.set('especialidad', data.especialidad)
			.set('imagen', data.imagen)
			.set('id_region', data.id_region)
			.set('descripcion', data.descripcion)
			.set('descripcionPromocion', data.descripcionPromocion)
			.set('detalles', data.detalles)
			.set('informacionAdicional', data.informacionAdicional)
			.set('condiciones', data.condiciones)
			.set('destacado', (data.destacado) ? '1' : '0')
			.set('fecha_inicio', initDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('fecha_fin', endDate.format('YYYY-MM-DD HH:mm:ss'))
			.set('orden', data.orden)
			.set('activo', data.activo);

		return this.httpClient.put<any>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id:number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}
}
