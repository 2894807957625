import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { SegmentoService } from 'src/app/shared/services/segmento.service';
import { SegmentoComponent } from '../segmento/segmento.component';

@Component({
	selector: 'app-upload-segmento',
	templateUrl: './upload-segmento.component.html',
	styleUrls: ['./upload-segmento.component.scss']
})
export class UploadSegmentoComponent implements OnInit {

	loading: boolean = false;
	fileText: string | ArrayBuffer;
	nombreSegmento: string;
	textInputValue: string = '';
	registerForm: FormGroup;

	constructor(public dialogRef: MatDialogRef<SegmentoComponent>,
		private notificationService: NotificationsService,
		public segmentoService: SegmentoService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: ISegmentoElement) {}

	ngOnInit() {
		this.nombreSegmento = this.data.nombre;
		this.registerForm = this.formBuilder.group({
			id_segmento: [this.data.id_segmento, Validators.required],
			fileName: [null, Validators.required],
			fileSource: [null, Validators.required],
			overwrite: [false]
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			this.segmentoService.uploadFile(this.registerForm.value).subscribe(() => {
				this.notificationService.success(':: Enviado satisfactoriamente');
				this.onClose(true);
			}, (error: any) => {
				this.notificationService.warn(`:: Error creando segmento: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

	onFileChange($event:any) {
		if ($event) {
			const file: File = $event.target.files[0];
			this.registerForm.patchValue({
				fileSource: file
			});
			let reader: FileReader = new FileReader();
			reader.onloadend = (e) => {
				this.fileText = reader.result;
			}
			reader.readAsText(file);
			this.textInputValue = file.name;
		}
	}
}
