import { Component, ViewChild, OnInit } from '@angular/core';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { MenuComponent } from '../menu/menu.component';
import { INavBarElement } from 'src/app/shared/interfaces/inavbar-element';
import { InformationSubmenuService } from 'src/app/shared/services/information-submenu.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu-list.component.html',
	styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {

	loading: boolean = true;

	dataSource = new MatTableDataSource<INavBarElement>();
	selection = new SelectionModel<INavBarElement>(true, []);

	public displayedColumns: string[] = [
		'nombre',
		'orden',
		'estado',
		'actions'
	];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private router: Router,
		protected navBarService: NavbarService,
		protected infoSubMenuServ: InformationSubmenuService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getNavBars();
		} else {
			this.notificationService.warn(':: Sesión expirada. Por favor, vuelva a iniciar sesión');
		}
	}

	getNavBars() {
		this.loading = true;
		this.navBarService.getNavBars().subscribe((data) => {
			this.dataSource.data = data;
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo menús: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(data?: INavBarElement) {
		this.dialog.open(MenuComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getNavBars();
			}
		});
	}

	deleteMenu(id: number) {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.navBarService.deleteMenu(id).subscribe((data) => {
					this.notificationService.success(':: Registro eliminado satisfactoriamente');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando mení: ${error.message}`);
				}).add(() => {
					this.getNavBars();
				});
			}
		});
	}

	getSubMenus(id: number) {
		this.router.navigate(['submenu'], { queryParams: { id }});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
