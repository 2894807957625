import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-send-reset',
	templateUrl: './send-reset.component.html',
	styleUrls: ['./send-reset.component.scss']
})
export class SendResetComponent implements OnInit {

	@Input() validated: boolean;
	@Input() reset: boolean;
	@Input() loading: boolean = false;

	constructor() { }

	ngOnInit() { }

}
