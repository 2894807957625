import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { IProductElement } from '../interfaces/iproduct-element';
import { ISiteElement } from '../interfaces/isite-element';
import { ISubNavBarElement } from '../interfaces/isubnavbar-element';
import { IContenidosElement } from '../interfaces/icontenidos-element';

const PATH: string = "/submenus";

@Injectable({
	providedIn: 'root'
})
export class SubnavbarService {

  informationForm: FormGroup;
  products: Array<number>;
  sites: Array<number>;
  initialValues: any;
  segmentos: Array<number>;

	constructor(
		protected httpClient: HttpClient
	) { }

	getSubmenus(): Observable<ISubNavBarElement[]> {
		return this.httpClient.get<ISubNavBarElement[]>(`/bff/admin${PATH}`);
	}

	getSubmenuProducts(id: number): Observable<IProductElement[]> {
		return this.httpClient.get<IProductElement[]>(`/bff/admin${PATH}/${id}/productos`);
	}

	getSubmenuSites(id: number): Observable<ISiteElement[]> {
		return this.httpClient.get<ISiteElement[]>(`/bff/admin${PATH}/${id}/sitios`);
	}

	getSubmenuContenidos(id: number): Observable<IContenidosElement[]> {
		return this.httpClient.get<IContenidosElement[]>(`/bff/admin${PATH}/${id}/contenidos`);
	}
	
	store(data: any): Observable<ISubNavBarElement> {
		const body = new HttpParams()
			.set('color', data.color)
			.set('color_hover', data.color_hover)
			.set('color_borde', data.color_borde)
			.set('descripcion', data.description)
			.set('estado', (data.active) ? '1' : '0')
			.set('icono', data.icon)
			.set('imagen', data.image)
			.set('link', data.link)
			.set('nombre', data.name)
			.set('strIndex', data.str_index)
			.set('orden', data.orden)
			.set('id_tipo_submenu', data.id_submenu_type)
			.set('titulo_descripcion', data.title)
			.set('visibleTop', data.visible_top)
			.set('visibleOnlyLogin', data.visible_login)
			.set('id_scmenu', localStorage.getItem('id_scmenu'))
			.set('id_producto', data.id_producto)
			.set('id_sitio', data.id_sitio)
			.set('tipo_perfilamiento', data.tipo_perfilamiento)
			.set('id_segmento', data.id_segmento)
			.set('idListContenidos', data.idListContenidos);

		return this.httpClient.post<ISubNavBarElement>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
		);
	}

	update(data: any): Observable<ISubNavBarElement> {					
		const body = new HttpParams()
			.set('color', data.color)
			.set('color_hover', data.color_hover)
			.set('color_borde', data.color_borde)
			.set('descripcion', data.description)
			.set('estado', (data.active) ? '1' : '0')
			.set('icono', data.icon)
			.set('imagen', data.image)
			.set('link', data.link)
			.set('nombre', data.name)
			.set('strIndex', data.str_index)
			.set('orden', data.orden)
			.set('id_tipo_submenu', data.id_submenu_type)
			.set('titulo_descripcion', data.title)
			.set('visibleTop', data.visible_top)
			.set('visibleOnlyLogin', data.visible_login)
			.set('id_producto', data.id_producto)
			.set('id_sitio', data.id_sitio)
			.set('tipo_perfilamiento', data.tipo_perfilamiento)
			.set('id_segmento', data.id_segmento)
			.set('idListContenidos', data.idListContenidos);

		return this.httpClient.put<ISubNavBarElement>(
			`/bff/admin${PATH}/${data.$key}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
		);
	}

	delete(id: number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}

	getInformationSubMenu(id: number) {
		return this.httpClient.get(`/bff/admin${PATH}/${id}/scinformacionsubmenu`);
	}

}
