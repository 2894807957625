import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { INoticiaResourceElement } from '../interfaces/inoticiaresource-element';
import { Observable } from 'rxjs/internal/Observable';
import { IPaisesElement } from '../interfaces/ipaises-element';

@Injectable({
	providedIn: 'root'
})

export class ResourcesService {

	constructor(
		protected httpClient: HttpClient
 	) { }
	
	upload(file: File, folder: string, rename: boolean) {
		const formData: FormData = new FormData();
		const renameString = rename? "true":"false";
		formData.append('file', file);
		formData.append('folder', folder);
		formData.append('rename', renameString);
		return this.httpClient.post<any>(
			'/bff/admin/resources',
			formData);
	}

	
setResourceNotcia(data: any) {
	const formData: FormData = new FormData();
	formData.append('link', data.link);
	formData.append('categoria', data.categoria);
	formData.append('orden', data.orden);
	formData.append('nombre', data.nombreResource);
	return this.httpClient.post<any>(
	  '/bff/admin/resources/comunicados/set',
	  formData);
  }
  
  
  updateResourceNotcia(data: any) {
	const formData: FormData = new FormData();
	formData.append('nombre', data.nombreResource);
	formData.append('link', data.link);
	formData.append('categoria', data.categoria);
	formData.append('orden', data.orden);
	return this.httpClient.post<any>(
	  '/bff/admin/resources/comunicados/set',
	  formData);
  }
  
	getResourcesNoticias(): Observable<INoticiaResourceElement[]> {
		  return this.httpClient.get<INoticiaResourceElement[]>('/bff/admin/resources/comunicados');
	  }
  
	  uploadResourceNoticia(file: File, folder: string, link: string, orden: number, categoria:number,rename: boolean) {
		  const formData: FormData = new FormData();
		  const renameString = rename? "1":"0";
		  formData.append('file', file);
		  formData.append('folder', folder);
	  formData.append('link', link);
	  formData.append('orden', orden.toString());
	  formData.append('categoria', categoria.toString());
		  formData.append('rename', renameString);
		  return this.httpClient.post<any>(
			  '/bff/admin/resources/comunicados',
			  formData);
	  }

	  delete(id: number) {
		return this.httpClient.delete('/bff/admin/resources/' + id);
	  }

	  getRegiones(): Observable<IPaisesElement> {
		return this.httpClient.get<IPaisesElement>('/bff/public/resource/json?name=regionescomunas.json');
	}
}
