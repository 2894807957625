import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'image'
})
export class ImagePipe implements PipeTransform {

	transform(url: string): string {
		const route = url.split('/');
    	const folder = route[2];
		const name = route[3];
		return `/bff/public/resource/${folder}?name=${name}`;
	}


}
