import { Component, OnInit, OnDestroy } from '@angular/core';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public data: any;

  constructor(
    private router: Router,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    this.data = this.jwtService.decodeToken;
    window.dispatchEvent(new Event('resize'));
    document.body.className = 'hold-transition skin-red sidebar-mini';
  }

  ngOnDestroy(): void {
    document.body.className = '';
  }
}
