import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { CKEditorConstants } from '../../shared/constants/ckeditor-constants';
import { CSegmentsConstants } from '../../shared/constants/csegments-constants';
import { ICategoryElement } from '../../shared/interfaces/icategory-element';
import { IExchangeTypeElement } from '../../shared/interfaces/iexchange-type-element';
import { IProductElement } from '../../shared/interfaces/iproduct-element';
import { ISegmentTypeElement } from '../../shared/interfaces/isegment-type-element';
import { ISegmentoElement } from '../../shared/interfaces/isegmento-element';
import { ITagElement } from '../../shared/interfaces/itag-element';
import { ExchangesTypeService } from '../../shared/services/exchanges-type.service';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ProductService } from '../../shared/services/product.service';
import { SegmentoService } from '../../shared/services/segmento.service';
import { TagsService } from '../../shared/services/tags.service';
import { CategoryService } from '../../shared/services/category.service';
import { IProductData } from '../IProductData';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

	loading: boolean = false;
	
	ckeConfig: any;
	idComponent: number;
	nameComponent = 'Producto';
	registerForm: FormGroup;

	categories: ICategoryElement[] = []
	loadingCategories: boolean = true;

	exchangesTypes: IExchangeTypeElement[] = []
	loadingExchangesTypes: boolean = true;

	tags: ITagElement[] = [];
	loadingTags: boolean = true;

	tiposPerfilamientos: ISegmentTypeElement[] = CSegmentsConstants.TiposPerfilamientos;
	segmentos: ISegmentoElement[] = [];
	loadingSegmentos: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<ProductComponent>,
		private notificationService: NotificationsService,
		public productService: ProductService,
		private categoryService: CategoryService,
		private exchangesTypeService: ExchangesTypeService,
		private tagService: TagsService,
		private segmentosService : SegmentoService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) private data: IProductData
	) { }

	ngOnInit() {
		this.ckeConfig = CKEditorConstants.ckeConfig;

		const idSubmenu = this.data.idSubmenu;
		const producto = this.data.producto;
		if (producto == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un producto */
			this.registerForm = this.formBuilder.group({
				id_producto: null,
				id_submenu: idSubmenu,
				condiciones_comercio: ['', Validators.required],
				direcciones: ['', Validators.required],
				condiciones_legales: ['', Validators.required],
				fecha_inicio: [null, Validators.required],
				fecha_fin: [null, Validators.required],
				imagen: ['', Validators.required],
				nombre: ['', [Validators.required, Validators.minLength(3)]],
				orden: [1, Validators.required],
				porcentaje_info: '',
				precio: 0,
				subtitulo: '',
				titulo: ['', Validators.required],
				titulo_tipo1: '',
				titulo_tipo2: '',
				codigo: '',
				estado: [true],
				destacado: [false],
				monitoreado: [false],
				requiere_validar_tarjetas: [false],
				requiere_validacion_sms: [false],
				id_categoria_producto: [1, Validators.required],
				id_tag: [[]],
				genera_canje: [false],
				cantida_maxima_canje: [{value: 0, disabled: false}],
				id_tipo_canje: [1, Validators.required],
				tipo_perfilamiento: 1,
				id_segmento: [[]]
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando un producto */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = producto.id_producto;
			/* Se llena la información del formulario */
			/* Se da formato a las fechas */
			const startDate = producto.fecha_inicio.split(' ');
			const endDate = producto.fecha_fin.split(' ');
			/* Se llena la información de los tags */
			let selectedTags:number[] = [];
			let tagsArray = producto.tags_producto;
			if (tagsArray instanceof Array) {
				tagsArray.forEach(element => {
					selectedTags.push(element.id_tag_producto);
				});
			}
			/* Se llena la información de los segmentos */
			let selectedSegmentos:number[] = [];
			let segmentosArray = producto.segmentos;
			if (segmentosArray instanceof Array) {
				segmentosArray.forEach(element => {
					selectedSegmentos.push(element.id_segmento);
				});
			}
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				id_producto: this.idComponent,
				id_submenu: idSubmenu,
				nombre: producto.nombre,
				orden: producto.orden,
				titulo: producto.titulo,
				subtitulo: producto.subtitulo,
				imagen: producto.imagen,
				porcentaje_info: producto.porcentaje_info,
				condiciones_comercio: producto.condiciones_comercio,
				direcciones: producto.direcciones,
				condiciones_legales: producto.condiciones_legales,
				precio: producto.precio,
				fecha_inicio: startDate[0] + 'T' + startDate[1],
				fecha_fin: endDate[0] + 'T' + endDate[1],
				titulo_tipo1: producto.titulo_tipo1,
				titulo_tipo2: producto.titulo_tipo2,
				codigo: producto.codigo,
				destacado: producto.destacado,
				estado: producto.estado,
				monitoreado: producto.monitoreado,
				requiere_validar_tarjetas: producto.requiere_validar_tarjetas,
				requiere_validacion_sms: producto.requiere_validacion_sms,
				id_categoria_producto: producto.id_categoria_producto,
				id_tag: [selectedTags],
				genera_canje: producto.genera_canje,
				cantida_maxima_canje: producto.cantida_maxima_canje,
				id_tipo_canje: producto.id_tipo_canje,
				tipo_perfilamiento: producto.tipo_perfilamiento,
				id_segmento: [selectedSegmentos]
			});
		}
		// CATEGORIES
		this.categoryService.getCategories().subscribe((res) => {
			this.loadingCategories = false;
			this.categories = res;
		});

		// EXCHANGES-TYPE
		this.exchangesTypeService.getExchangesType().subscribe((res) => {
			this.loadingExchangesTypes = false;
			this.exchangesTypes = res;
		});

		// TAGS
		this.tagService.getTags().subscribe((res) => {
			this.loadingTags = false;
			this.tags = res;
		});

		// SEGMENTS
		this.segmentosService.getSegmentos().subscribe((res) => {
			this.loadingSegmentos = false;
			this.segmentos = res;
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			let saveProductObservable: Observable<IProductElement>;
			if (!this.registerForm.get('id_producto').value) {
				saveProductObservable = this.productService.store(this.registerForm.value)
			} else {
				saveProductObservable = this.productService.update(this.registerForm.value)
			}
			saveProductObservable.subscribe(() => {
				this.notificationService.success(':: Guardado satisfactoriamente');
				this.onClose(true);
			}, (error) => {
				this.notificationService.warn(`:: Error guardando producto: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	onClose(confirmation: boolean) {
		this.dialogRef.close(confirmation);
	}

}
