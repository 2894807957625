import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { EventoService } from '../../shared/services/evento.service';
import { CreateEventoComponent } from '../create-evento/create-evento.component';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-evento-list',
  templateUrl: './evento-list.component.html',
  styleUrls: ['./evento-list.component.scss']
})
export class EventoListComponent implements OnInit {

  loading: boolean;
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'titulo',
    'fechaInicio',
    'fechaFin',
    'imagen',
    'estado',
    'actions'
  ];

	constructor(
		protected eventosService: EventoService,
	   	private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) {
		this.loading = true;
	}

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getEventos();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

	getEventos() {
		this.eventosService.getEventos().subscribe((data: any) => {
			this.dataSource.data = data as any[];
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo eventos: ${error.message}`);
		}, () => { this.loading = false; });
	}

	openModal(data ? : any) : void {
		this.dialog.open(CreateEventoComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data : data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getEventos();
			}
		});
	}

  obtenerPostulantes(idEvento:number){
    window.open(
      "/bff/public/resource/eventos/"+idEvento+"/postulantes", "_blank");
  }
  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id_usuario_cms + 1}`;
  }

}
