import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _moment from 'moment';
import 'moment/locale/es';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';
import { IRegionesElement } from 'src/app/shared/interfaces/iregiones-element';
import { ICommunesElement } from 'src/app/shared/interfaces/icommunes-element';
import { SegmentoService } from 'src/app/shared/services/segmento.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';

interface datosInterface {
  valor: number;
  valorVista: string;
  }
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class CustomerComponent implements OnInit, OnDestroy {
    public loading: boolean;
    public estado: boolean;
    public submitted: boolean;
    public selected: boolean;
    public idComponent: number;
    public customerForm: FormGroup;
    public nameComponent = 'cliente';
    public subscription: Subscription;
    public initialValues : any;
    public regionDefault: string ='13';
    public segmento:ISegmentoElement;
    public segmento$:Observable<any>;
    public selectedSegmento:any;
    public segmentoLoading=false;
    public segmentoInput$=new Subject<string>();
    public regiones: IRegionesElement[] = [];
    public comunas: ICommunesElement[] = [];
    public loadingRegiones: boolean = true;
    public estados: datosInterface[] = [
      {valor: 1, valorVista: 'Habilitado'},
      {valor: 2, valorVista: 'Bloqueado'},
      {valor: 3, valorVista: 'Deshabilitado'}
    ];
    public accesos: datosInterface[] = [
      {valor: 1, valorVista: 'Cliente'},
      {valor: 2, valorVista: 'Moderador'}
    ];
    saving: boolean = false;

    constructor(
      public dialogRef: MatDialogRef<CustomerComponent>,
      public resourcesService: ResourcesService,
      public notificationService: NotificationsService,
      public customerService: CustomerService,
      private segmentosService : SegmentoService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder
    ) {
      this.customerForm = this.formBuilder.group({
        idCustomer: null,
        primerNombre: '',
        segundoNombre: '',
        rut: ['', Validators.required],
        primerApellido: null,
        segundoApellido: '',
        telefono:['', [Validators.pattern('^[0-9]+$')]],
        email: ['', [Validators.email]],
        region:this.regionDefault,
        comuna:'',
        direccion:'',
        fechaCreacion: '',
        fechaUltimoLogin: '',
        fechaNacimiento: '',
        estado:1,
        acceso:1,
        segmentos:Array()
  
      });
      this.estado = true;
      this.idComponent = this.customerForm.get('idCustomer').value;
      this.initialValues = this.customerForm.value;
    
     }
  
    ngOnInit() {
      this.resourcesService.getRegiones().subscribe((res) => {
        this.loadingRegiones = false;
        /* Traemos las regiones del unico pais que tiene el json hasta el momento. Chile */
        this.regiones = res.regions;
        if(this.data!=null){
            this.seleccionarComunas(this.data.region.idRegion.toString(), false);
        }else{
            this.seleccionarComunas(this.regionDefault, true);
        }
      }, (error: any) => {
        this.notificationService.warn(`:: Error obteniendo Paises: ${error.message}`);
      });
      /* Asigna valores al form de un registro existente */
      if(this.data!=null){
        const customerExistente = {
          idCustomer: this.data.idCustomer,
          primerNombre: this.data.primerNombre,
          segundoNombre: this.data.segundoNombre,
          primerApellido: this.data.primerApellido,
          segundoApellido: this.data.segundoApellido,
          rut: this.data.rut,
          email:this.data.email,
          estado: this.data.estado,
          telefono: this.data.telefono,
          fechaUltimoLogin: this.data.fechaUltimoLogin,
          fechaCreacion: this.data.fechaCreacion,
          fechaNacimiento: this.data.fechaNacimiento,
          acceso: this.data.acceso,
          direccion:this.data.direccion,
          region: this.data.region.idRegion.toString(),
          comuna: this.data.comuna,
          segmentos: (Array.isArray(this.data.segmentosCustomer)) ? this.data.segmentosCustomer : Array(),
    
        }
      this.customerForm.setValue(customerExistente);
      this.selectedSegmento=this.data.segmentosCustomer;

    }
    this.customerForm.get('fechaUltimoLogin').disable(); 
    this.customerForm.get('fechaCreacion').disable();
    this.segmento$ = this.segmentosService.getSegmentos();

  } // cierra ngOnInit
  
  /*Crea el user */
    onSubmit() {
      if (this.customerForm.valid) {
        if (this.customerForm.get('idCustomer').value) {
          this.customerService.update(this.customerForm.value).subscribe((res: any) => {
            this.submitted = true;
            this.notificationService.message(':: Enviado satisfactoriamente', 'success');
            this.onClose(true);
          }, (error: any) => {
            console.error(error);
          }, () => {
            console.log('Observer got a complete notification');
          });
        }else{
          this.customerService.save(this.customerForm.value).subscribe((res: any) => {
            this.submitted = true;
            this.notificationService.message(':: Enviado satisfactoriamente', 'success');
            this.onClose(true);
          }, (error: any) => {
            console.error(error);
          }, () => {
            console.log('Observer got a complete notification');
          });
        }
      }
    }

    
  
    get f() { return this.customerForm.controls; }
  
    seleccionarComunas(valorSeleccionado: any, select:boolean): void {
      for(const region of this.regiones){
        if(region.number == valorSeleccionado){
          this.comunas = region.communes;
          if(select){

            this.customerForm.get('comuna').setValue(this.comunas[0].name);  
          } 
        }
      } 
    }
	
  
    onChange($event: any) {
      this.estado = $event.checked;
    }
  
    onClose(confirmation: boolean) {
      this.customerForm.reset();
      this.dialogRef.close(confirmation);
    }
  
  
    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }


}
