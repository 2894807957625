import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ICustomerElement } from 'src/app/shared/interfaces/icustomer-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { Location } from '@angular/common';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { CustomerComponent } from '../customer/customer.component';
import { CustomerPointsComponent } from '../customer-points/customer-points.component';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  loading: boolean;
  dataSource = new MatTableDataSource<ICustomerElement>();
  selection = new SelectionModel<ICustomerElement>(true, []);
  hidden : boolean;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private activeRouter: ActivatedRoute,
    private location: Location,
    private navigator: Router,
    protected customerService: CustomerService,
    private dialogService: DialogService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
      this.loading = true;
  }

  public displayedColumns: string[] = [
    'idCustomer',
    'rut',
    'fechaCreacion',
    'email',
    'telefono',
    'fechaUltimoLogin',
    'estado',
    'actions'
  ];

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getCustomers();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getCustomers() {
    this.activeRouter.queryParams.subscribe(params => {
    this.hidden = (params.id_customer) ? true : false;
    this.customerService.getCustomers().subscribe((data: any) => {
    this.dataSource.data = data as ICustomerElement[];
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
      });
  }
	/* Se abre el modal para crear o editar un customer */
	openModal(data ? : any) : void {
		this.dialog.open(CustomerComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data : data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getCustomers();
			}
		});
	}

 
  openModalPassword(data: any) {
    this.dialogService.openConfirmDialog('Se le enviará una nueva clave al cliente '+data.primerNombre+' '+data.primerApellido+' al correo '+data.email+' ¿estás seguro de esto?').afterClosed().subscribe(res => {
      if (res) {
        this.customerService.resetPassword(data.idCustomer).subscribe((res) => {
          this.notificationService.message(':: Contraseña reestablecida', 'success');
        }, (error) => {
          this.notificationService.warn(`:: Error actualizando: ${error.error.message}`);
        }).add( () => {

        });
      }
    });
}

openModalPoints(data ? : any) : void {
  this.dialog.open(CustomerPointsComponent, {
    width: '60%',
    height: '85%',
    autoFocus: true,
    disableClose: true,
    data : data
  }).afterClosed().subscribe((res) => {
    if (res) {
      this.getCustomers();
    }
  });
}


    /** Filter rows matches. */
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ICustomerElement): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idCustomer + 1}`;
    }

}
