import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryService } from '../../shared/services/category.service';
import { ICategoryElement } from 'src/app/shared/interfaces/icategory-element';
import { CreateCategoryComponent } from '../create-category/create-category.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

	loading: boolean = true;
	dataSource = new MatTableDataSource<ICategoryElement>();
	selection = new SelectionModel<ICategoryElement>(true, []);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public displayedColumns: string[] = [
		'nombre',
		'tipo',
		'actions'
	];

	constructor(
		public categoryService: CategoryService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) { }

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getCategories();
		} else {
			this.notificationService.warn(':: Sesión expirada. Por favor, vuelva a iniciar sesión');
		}
	}

	getCategories() {
		this.loading = true;
		this.categoryService.getCategories().subscribe((data: any) => {
			this.dataSource.data = data as ICategoryElement[];
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo categorías: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(data?: ICategoryElement) {
		this.dialog.open(CreateCategoryComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getCategories();
			}
		});
	}

	delete(id: number) {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.categoryService.delete(id).subscribe((data) => {
					this.notificationService.success(':: Registro eliminado satisfactoriamente');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando categoría: ${error.message}`);
				}).add(() => {
					this.getCategories();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
