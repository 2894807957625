import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomURLEncoder } from '../../urlencoder/component';
import { ICategoryElement } from '../interfaces/icategory-element';

const PATH: string = "/admin/categoriaproducto";

@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	constructor(protected httpClient: HttpClient) { }

	getCategories(incluir_productos = false): Observable<ICategoryElement[]> {
		return this.httpClient.get<ICategoryElement[]>(`/bff${PATH}?incluir_productos=${incluir_productos}`);
	}

	getCategory(id: number): Observable<ICategoryElement> {
		return this.httpClient.get<ICategoryElement>(`/bff${PATH}/${id}`);
	}

	store(data: any): Observable<ICategoryElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.name)
			.set('condiciones_legales', data.terms)
			.set('descripcion', data.descripcion)
			.set('strindex', data.str_index)
			.set('orden', data.orden)
			.set('color_hover', data.color_hover)
			.set('imagen', data.image)
			.set('tipo', data.type)
			.set('id_rubro', data.id_item)
			.set('nominativa', data.nominative);

		return this.httpClient.post<ICategoryElement>(
			`/bff${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<ICategoryElement>  {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.name)
			.set('condiciones_legales', data.terms)
			.set('descripcion', data.descripcion)
			.set('strindex', data.str_index)
			.set('orden', data.orden)
			.set('color_hover', data.color_hover)
			.set('imagen', data.image)
			.set('tipo', data.type)
			.set('id_rubro', data.id_item)
			.set('nominativa', data.nominative);

		return this.httpClient.put<ICategoryElement>(
			`/bff${PATH}/${data.id_categoria_producto}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id: number) {
		return this.httpClient.delete(`/bff${PATH}/${id}`);
	}
}
