import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {CustomURLEncoder} from '../../urlencoder/component';
import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      codigo: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      tipoTicket: ['', [Validators.required]],
      valorPuntos: ['', [Validators.required]],
      fechaQuemado: moment(),
      fechaExpiracionTicket:  moment(),
      idcliente: ['', [Validators.required]],
      rut: ['', [Validators.required]]
      
    });
  }

  getHistory(val) {
    //busqueda con guion
    if (val.indexOf('-') == -1){
      val = val.slice(0, -1) + '-'+val.slice(val.length - 1);
    }

    return this.httpClient.get('/bff/admin/jos-tickets/by-rut/'+val);
  }

  getHistory2(val) {
    //busqueda sin guion
    val = val.replace("-", "");
    return this.httpClient.get('/bff/admin/jos-tickets/ticketera-by-rut/'+val);
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }



}
