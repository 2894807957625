import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IItemElement } from '../interfaces/iitem-element';
import {CustomURLEncoder} from '../../urlencoder/component';


@Injectable({
  providedIn: 'root'
})
export class ItemService {

  registerForm: FormGroup;

  constructor(
    protected httpClient: HttpClient,
    public formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: null,
      code: ['', Validators.required],
      name: ['', Validators.required]
    });
  }

  getItems(): Observable<IItemElement[]> {
    return this.httpClient.get<IItemElement[]>('/bff/admin/rubros');
  }

  edit(data: any) {
    this.registerForm.setValue(data);
  }

  store(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.name)
      .set('codigo', data.code);

    return this.httpClient.post<IItemElement>(
      '/bff/admin/rubros',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('nombre', data.name)
      .set('codigo', data.code);

    return this.httpClient.put<IItemElement[]>(
      '/bff/admin/rubros/' + data.id,
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/bff/admin/rubros/' + id);
  }
}
