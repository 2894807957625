import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ResourcesService } from '../../shared/services/resources.service';
import { MessageService } from 'src/app/shared/services/message.service';

export interface ResourceElement {
  id: number;
  nombre_resource: string;
  mime_resource: string;
  folder: number;
  checksum_resource: string;
  data: string;
}

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss']
})

export class ResourceListComponent {

  loading: boolean;
  dataSource: ResourceElement;
  selection = new SelectionModel<ResourceElement>(true, []);
  upload: boolean;

  tiles = [
    {text: 'One', cols: 2, rows: 1, color: '#142A5C'},
    {text: 'Two', cols: 1, rows: 1, color: '#B7A0E8'},
    {text: 'Three', cols: 1, rows: 2, color: '#FF0000'},
    {text: 'Four', cols: 3, rows: 1, color: '#D9EDD9'},
    {text: 'One', cols: 2, rows: 1, color: '#142A5C'},
    {text: 'Two', cols: 1, rows: 1, color: '#B7A0E8'},
    {text: 'Three', cols: 1, rows: 2, color: '#FF0000'},
    {text: 'Four', cols: 3, rows: 1, color: '#D9EDD9'},
    {text: 'One', cols: 2, rows: 1, color: '#142A5C'},
    {text: 'Two', cols: 1, rows: 1, color: '#B7A0E8'},
    {text: 'Three', cols: 1, rows: 2, color: '#FF0000'},
    {text: 'Four', cols: 3, rows: 1, color: '#D9EDD9'}
  ];

  constructor(
    public dialogRef: MatDialogRef<ResourceListComponent>,
    private resourcesService: ResourcesService,
    private messageService: MessageService
  ) {
    this.loading = true;
    this.upload = false;
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    console.log(inputNode.files[0].name);
    this.messageService.sendMessage(inputNode.files[0].name);
  }

  onClose() {
    // this.form.reset();
    // this.initializeFormGroup();
    this.dialogRef.close();
  }

  getResource(resource: any) {
    console.log(resource);
  }

}
