import { Component, OnInit, OnDestroy, Inject, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _moment from 'moment';
import 'moment/locale/es';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-customer-points',
  templateUrl: './customer-points.component.html',
  styleUrls: ['./customer-points.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class CustomerPointsComponent implements OnInit, OnDestroy {
    public loading: boolean;
    public estado: boolean;
    public submitted: boolean;
    public selected: boolean;
    public idComponent: number=0;
    public customerPointsForm: FormGroup;
    public nameComponent = 'Puntos Cliente';
    public subscription: Subscription;
    public initialValues : any;
    saving: boolean = false;

    constructor(
      public dialogRef: MatDialogRef<CustomerPointsComponent>,
      public notificationService: NotificationsService,
      public customerService: CustomerService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder
    ) {
      this.customerPointsForm = this.formBuilder.group({
        idCustomer: null ,
        primerNombre: new FormControl({ value: '', disabled: true }),
        rut: new FormControl({ value: '', disabled: true }),
        puntos:[0, [Validators.pattern('^[0-9]+$')]],
        descripcion:'Abono de puntos asignados por Administrador de Sistema',
        email: new FormControl({ value: '', disabled: true })
      });
      this.initialValues = this.customerPointsForm.value;
    
     }
  
    ngOnInit() {
      /* Asigna valores al form de un registro existente */
      if(this.data!=null){
        const customerExistente = {
          idCustomer: this.data.idCustomer,
          primerNombre: this.data.primerNombre+' '+this.data.segundoNombre+' '+this.data.primerApellido+' '+this.data.segundoApellido,
          rut: this.data.rut,
          puntos:0,
          descripcion:'Abono de puntos asignados por Administrador de Sistema',
          email:this.data.email
        }
      this.idComponent = this.customerPointsForm.get('idCustomer').value;
      this.customerPointsForm.setValue(customerExistente);

    }

  } // cierra ngOnInit
  
  /*Crea el user */
    onSubmit() {
      this.customerService.agregarPointsCustomer(this.customerPointsForm.value).subscribe((res: any) => {
        this.submitted = true;
        this.notificationService.message(':: Enviado satisfactoriamente', 'success');
        this.onClose(true);
      }, (error: any) => {
        console.error(error);
      }, () => {
        console.log('Observer got a complete notification');
      });
    }


  
    get f() { return this.customerPointsForm.controls; }

	
  
    onChange($event: any) {
      this.estado = $event.checked;
    }
  
    onClose(confirmation: boolean) {
      this.customerPointsForm.reset();
      this.dialogRef.close(confirmation);
    }
  
  
    ngOnDestroy(): void {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }


}
