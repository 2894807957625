import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SubmenuComponent } from '../submenu/submenu.component';
import { Location } from '@angular/common';
import { InformationSubmenuService } from 'src/app/shared/services/information-submenu.service';
import { InformationSubmenuComponent } from 'src/app/information/information-submenu/information-submenu.component';
import { SubnavbarService } from '../../shared/services/subnavbar.service';
import { ISubNavBarElement } from 'src/app/shared/interfaces/isubnavbar-element';
import { SubmenuInformationService } from 'src/app/shared/services/submenu-information.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-submenu-list',
  templateUrl: './submenu-list.component.html',
  styleUrls: ['./submenu-list.component.scss']
})
export class SubmenuListComponent implements OnInit {

  loading = true;
  dataSource = new MatTableDataSource<ISubNavBarElement>();
  selection = new SelectionModel<ISubNavBarElement>(true, []);

  public displayedColumns: string[] = [
    'nombre',
    'link',
    'estado',
    'actions'
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private navigator: Router,
    private router: ActivatedRoute,
    protected infoSubMenuServ: InformationSubmenuService,
    public subMenuInfoServ: SubmenuInformationService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
	private location: Location,
	private menuService: NavbarService,
    protected subNavBarService: SubnavbarService,
    protected jwtService: JwtService
  ) { this.loading = true; }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getSubNavBars();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

	getSubNavBars() {
		this.router.queryParams.subscribe(params => {
			localStorage.setItem('id_scmenu', params.id);
			this.menuService.getSubmenus(params.id).subscribe((data: any) => {
				this.dataSource.data = data as ISubNavBarElement[];
			}, (error) => {
				this.notificationService.warn(`:: Error obteniendo submenús: ${error.message}`);
			}, () => { this.loading = false; });
		});
	}

	openModal(data?: any) {
		this.dialog.open(SubmenuComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.loading = true;
				this.getSubNavBars();
			}
		});
	}

  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.subNavBarService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.ngOnInit(); });
      }
    });
  }

	navigateSubmenuProducts(id: number) {
		this.navigator.navigate(['products'], { queryParams: { 'id_submenu': id }});
	}

	navigateSubmenuSites(id: number) {
		this.navigator.navigate(['sites'], { queryParams: { 'id_submenu': id }});
	}

  information(id: number) {
    this.subNavBarService.getInformationSubMenu(id).subscribe((res: any) => {
      if (res[0]) {
        const logo = res[0].imagen_logo.split('/');
        const imagen = res[0].imagen.split('/');
        const data = {
          id: res[0].id_scsubmenu_informacion,
          image: imagen[3],
          title: res[0].titulo,
          subtitle: res[0].subtitulo,
          description: res[0].descripcion,
          link: res[0].link,
          activated: res[0].estado,
          logo: logo[3],
          id_submenu: res[0].id_submenu,
          str_index: res[0].submenu_strindex,
          link_text: res[0].texto_link,
          terms: res[0].condiciones
        };
        this.subMenuInfoServ.edit(data);
      } else {
        const data = {
          id: null,
          image: null,
          title: null,
          subtitle: null,
          description: null,
          link: null,
          activated: null,
          logo: null,
          id_submenu: id,
          str_index: null,
          link_text: null,
          terms: null
        };
        this.subMenuInfoServ.edit(data);
      }
    }, (error) => {
      console.error(error);
    }, () => { });

    this.dialog.open(InformationSubmenuComponent, {
      width: '60%',
      height: '85%',
      autoFocus: true,
      disableClose: true
    });
  }

  /* Back to Menu */
  back() {
    this.location.back();
  }

  /** Filter rows matches. */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ISubNavBarElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
