import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ITagElement } from 'src/app/shared/interfaces/itag-element';
import { TagsService } from '../../shared/services/tags.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CreateTagComponent } from '../create-tag/create-tag.component';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {

	loading: boolean = true;
	dataSource = new MatTableDataSource<ITagElement>();
	selection = new SelectionModel<ITagElement>(true, []);

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	public displayedColumns: string[] = [
		'icono',
		'nombre',
		'descripcion',
		'estado',
		'actions'
	];

	constructor(
		protected tagService: TagsService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService
	) { }

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getTags();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getTags() {
		this.loading = true;
		this.tagService.getTags().subscribe((data) => {
			this.dataSource.data = data;
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo etiquetas: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(data?: any) {
		this.dialog.open(CreateTagComponent, {
			width: '60%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getTags();
			}
		});
	}

	delete(id: number) {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.tagService.delete(id).subscribe((data: any) => {
					this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando etiqueta: ${error.message}`);
				}).add(() => {
					this.getTags();
				});
			}
		});
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
