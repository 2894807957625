import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IProductElement } from '../interfaces/iproduct-element';

import {CustomURLEncoder} from '../../urlencoder/component';
import { DateFormatter } from '../utils/date-formatter';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

const PATH: string = "/productos";

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	tags: number[];

	constructor(protected httpClient: HttpClient) { }

	getProducts(): Observable<IProductElement[]> {
		return this.httpClient.get<IProductElement[]>(`/bff/admin${PATH}`);
	}


	getProduct(id: number): Observable<IProductElement[]> {
		return this.httpClient.get<IProductElement[]>(`/bff/admin${PATH}/${id}`);
	}

	store(data: any) {
		const initDate = data.fecha_inicio;
		const endDate = data.fecha_fin;
		const initDateFormatted = DateFormatter.format(initDate);
		const endDateFormatted = DateFormatter.format(endDate);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
		.set('condiciones_comercio', data.condiciones_comercio)
		.set('direcciones', data.direcciones)
		.set('condiciones_legales', data.condiciones_legales)
		.set('fecha_inicio', initDateFormatted)
		.set('fecha_fin', endDateFormatted)
		.set('imagen', data.imagen)
		.set('nombre', data.nombre)
		.set('orden', data.orden)
		.set('porcentaje_info', data.porcentaje_info)
		.set('precio', data.precio)
		.set('subtitulo', data.subtitulo)
		.set('titulo', data.titulo)
		.set('titulo_tipo1', data.titulo_tipo1)
		.set('titulo_tipo2', data.titulo_tipo2)
		.set('codigo', data.codigo)
		.set('estado', (data.estado) ? '1' : '0')
		.set('destacado', (data.destacado) ? '1' : '0')
		.set('monitoreado', data.monitoreado)
		.set('requiere_validar_tarjetas', data.requiere_validar_tarjetas)
		.set('requiere_validacion_sms', data.requiere_validacion_sms)
		.set('id_categoria_producto', data.id_categoria_producto)
		.set('cantidad_maxima_canje', data.cantida_maxima_canje)
		.set('id_tipo_canje', data.id_tipo_canje)
		.set('genera_canje', data.genera_canje)
		.set('id_tag_producto', data.id_tag)
		.set('tipo_perfilamiento', data.tipo_perfilamiento)
		.set('id_segmento', data.id_segmento)
		.set('id_submenu', data.id_submenu ? data.id_submenu : '');

		return this.httpClient.post<any>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any) {
		const initDate = data.fecha_inicio;
		const initDateFormatted = DateFormatter.format(initDate);
		const endDate = data.fecha_fin;
		const endDateFormatted = DateFormatter.format(endDate);

		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('condiciones_comercio', data.condiciones_comercio)
			.set('direcciones', data.direcciones)
			.set('condiciones_legales', data.condiciones_legales)
			.set('fecha_inicio', initDateFormatted)
			.set('fecha_fin', endDateFormatted)
			.set('imagen', data.imagen)
			.set('nombre', data.nombre)
			.set('orden', data.orden)
			.set('porcentaje_info', data.porcentaje_info)
			.set('precio', data.precio)
			.set('subtitulo', data.subtitulo)
			.set('titulo', data.titulo)
			.set('titulo_tipo1', data.titulo_tipo1)
			.set('titulo_tipo2', data.titulo_tipo2)
			.set('codigo', data.codigo)
			.set('estado', (data.estado) ? '1' : '0')
			.set('destacado', (data.destacado) ? '1' : '0')
			.set('monitoreado', data.monitoreado)
			.set('requiere_validar_tarjetas', data.requiere_validar_tarjetas)
			.set('requiere_validacion_sms', data.requiere_validacion_sms)
			.set('id_categoria_producto', data.id_categoria_producto)
			.set('cantidad_maxima_canje', data.cantida_maxima_canje)
			.set('id_tipo_canje', data.id_tipo_canje)
			.set('genera_canje', data.genera_canje)
			.set('id_tag_producto', data.id_tag)
			.set('tipo_perfilamiento', data.tipo_perfilamiento)
			.set('id_segmento', data.id_segmento);

		return this.httpClient.put<any>(
		`/bff/admin${PATH}/${data.id_producto}`,
		body.toString(),
		{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
	}

	delete(id: number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}
}
