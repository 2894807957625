import { Component, OnInit, Inject, Optional, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from '../../shared/services/notifications.service';
import { ResourcesService } from '../../shared/services/resources.service';
import { INoticiaResourceElement } from '../../shared/interfaces/inoticiaresource-element';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';

/* Interfaz para categorias */
interface Categorias {
	valor: number;
	valorVista: string;
}

@Component({
	selector: 'app-noticiasresources',
	templateUrl: './noticiasresources.component.html',
	styleUrls: ['./noticiasresources.component.scss']
})
export class NoticiasResourcesComponent implements OnInit {

	idComponent: number;
	nameComponent = 'Anexo o Imagen';
	registerForm: FormGroup;
	loading: boolean;
	submitted: boolean;
    private file: File | null = null;

	public categorias: Categorias[] = [
		{valor: 2, valorVista: 'Sin categoría'},
		{valor: 3, valorVista: 'Anexo'},
		{valor: 4, valorVista: 'Imagen'},
		{valor: 5, valorVista: 'Imagen con Link'}
	  ];

    @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
      const file = event && event.item(0);
      this.file = file;
    }

	constructor(
		public dialogRef: MatDialogRef<INoticiaResourceElement>,
		private notificationService: NotificationsService,
		private resourcesService: ResourcesService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.loading = false;
		this.registerForm = this.formBuilder.group({
			$key: null,
			categoria: ['', [Validators.required]],
			link: null,
			orden: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
			nombreResource: ['', [Validators.required]]
		});
	}

	ngOnInit() {

		if (this.data != null) {
			this.idComponent = this.data.id_resource;
			const userExistente = {
				$key: this.data.id_resource,
				categoria: this.data.categoria,
				link: this.data.link,
				orden: this.data.orden,
				nombreResource: this.data.nombre_resource
			}
			this.definirImagenLink(this.data.categoria);
			this.registerForm.setValue(userExistente); 
		}
	}


    writeValue( value: null ) {
        // clear file input
      //  this.noticiaResourceElement.nativeElement.value = '';
        this.file = null;
      }
    /*
      registerOnChange( fn: Function ) {
        this.onChange = fn;
      }
      */
    
      registerOnTouched( fn: Function ) {
      }
	onSubmit() {
		if (this.registerForm.valid) {
			if (!this.registerForm.get('$key').value) {
				this.resourcesService.setResourceNotcia(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			} else {
				this.resourcesService.updateResourceNotcia(this.registerForm.value).subscribe((res) => {
					this.submitted = true;
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					console.error(error);
				}, () => {
					console.log('Observer got a complete notification');
				});
			}
		}
	}

	onFileInput(fileInput: any, $event: any) {
		$event.preventDefault();
		fileInput.click();
	}

	onFileSelected() {
		const inputNode: any = document.querySelector('#file') as HTMLInputElement;
		this.registerForm.controls.imagen.setValue(inputNode.files[0].name);
		this.resourcesService.upload(inputNode.files[0], 'images', true).subscribe((data: any) => {
			console.log(data);
		}, (error: any) => {
			console.error(error);
		}, () => {
			this.loading = false;
		});
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

	public definirImagenLink(event){
		if(event!=5){
		  this.registerForm.get('link').disable();
		  this.registerForm.get('link').clearValidators();
		  this.registerForm.get('link').updateValueAndValidity();
		  this.registerForm.get('categoria').updateValueAndValidity();
		}else{
		  this.registerForm.get('link').enable();
		  this.registerForm.get('link').setValidators([Validators.required, Validators.minLength(6)])
		  this.registerForm.get('link').updateValueAndValidity();
		  this.registerForm.get('categoria').updateValueAndValidity();
		}
	  }
}
function requiredFileType(arg0: string): import("@angular/forms").ValidatorFn {
    throw new Error('Function not implemented.');
}

