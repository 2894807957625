import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { IEventoElement } from '../interfaces/ieventos-element';
import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/eventos";

@Injectable({
	providedIn: 'root'
})
export class EventoService {
	constructor(
		protected httpClient: HttpClient
	) { }

	getEventos(): Observable<IEventoElement[]> {
		return this.httpClient.get<IEventoElement[]>('/bff/admin' + PATH);
	}

	update(data: any): Observable<IEventoElement[]> {
		const body = new HttpParams({ encoder: new CustomURLEncoder() })
		.set('fechaInicioTexto', data.fechaInicio)
		.set('fechaFinTexto', data.fechaFin)
		.set('titulo', data.titulo)
		.set('descripcion', data.descripcion)
		.set('imagen', data.imagen)
		.set('background', data.background)
		.set('region', data.region)
		.set('tipo', data.tipo)
		.set('video', data.video)
		.set('comuna', data.comuna)
		.set('direccion', data.direccion)
		.set('datoAdicional', data.datoAdicional)
		.set('datoAdicionalDos', data.datoAdicionalDos)
		.set('estado', data.estado);



		return this.httpClient.put<any>(
			'/bff/admin' + PATH + '/' + data.idEvento,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	
	save(data: any): Observable<IEventoElement[]> {
		const body = new HttpParams({ encoder: new CustomURLEncoder() })
		.set('fechaInicioTexto', data.fechaInicio)
		.set('fechaFinTexto', data.fechaFin)
		.set('titulo', data.titulo)
		.set('descripcion', data.descripcion)
		.set('imagen', data.imagen)
		.set('background', data.background)
		.set('region', data.region)
		.set('tipo', data.tipo)
		.set('video', data.video)
		.set('comuna', data.comuna)
		.set('direccion', data.direccion)
		.set('datoAdicional', data.datoAdicional)
		.set('datoAdicionalDos', data.datoAdicionalDos)
		.set('estado', data.estado);



		return this.httpClient.post<any>(
			'/bff/admin' + PATH ,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}


}
