import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _moment from 'moment';
import 'moment/locale/es';
import { ICustomerElement } from '../interfaces/icustomer-element';
import {CustomURLEncoder} from '../../urlencoder/component';

const moment = _moment;
moment.locale('es');

const PATH: string = "/customers";

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	segmentos: Array<number>;
	idRegion:number;
	constructor(
		protected httpClient: HttpClient
	) { }

	getCustomers(): Observable<ICustomerElement[]> {
		return this.httpClient.get<ICustomerElement[]>('/bff/admin' + PATH);
	}

	update(data: any): Observable<ICustomerElement[]> {
		this.segmentos = [];
		/* Validamos si hay segmentos para pasar de ser así por cada video rescatamos el id
		y lo guardamos en el array Number 'segmentos' */
		if (Array.isArray(data.segmentos)) {
			data.segmentos.forEach((value: any, key: any) => {
				console.log(value.id_segmento);
			this.segmentos.push(value.id_segmento);
			});
		}
        const body = new HttpParams({encoder: new CustomURLEncoder()})
            .set('rut', data.rut)
            .set('primerNombre', data.primerNombre)
            .set('segundoNombre', data.segundoNombre)
			.set('primerApellido', data.primerApellido)
			.set('segundoApellido', data.segundoApellido)
			.set('email', data.email)
			.set('telefono', data.telefono)
			.set('region',  data.region)
			.set('comuna', data.comuna)
			.set('direccion', data.direccion)
			.set('estado', data.estado)
			.set('acceso', data.acceso)
			.set('segmentos', this.segmentos.join(','));



		return this.httpClient.put<any>(
			'/bff/admin' + PATH + '/' + data.idCustomer,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	save(data: any): Observable<ICustomerElement[]> {
		this.segmentos = [];
		/* Validamos si hay segmentos para pasar de ser así por cada video rescatamos el id
		y lo guardamos en el array Number 'segmentos' */
		if (Array.isArray(data.segmentos)) {
			data.segmentos.forEach((value: any, key: any) => {
				console.log(value.id_segmento);
			this.segmentos.push(value.id_segmento);
			});
		}
        const body = new HttpParams({encoder: new CustomURLEncoder()})
            .set('rut', data.rut)
            .set('primerNombre', data.primerNombre)
            .set('segundoNombre', data.segundoNombre)
			.set('primerApellido', data.primerApellido)
			.set('segundoApellido', data.segundoApellido)
			.set('email', data.email)
			.set('telefono', data.telefono)
			.set('region',  data.region)
			.set('comuna', data.comuna)
			.set('direccion', data.direccion)
			.set('estado', data.estado)
			.set('acceso', data.acceso)
			.set('segmentos', this.segmentos.join(','));



		return this.httpClient.post<any>(
			'/bff/admin' + PATH + '/',
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	resetPassword(idUsuario: number): Observable<string>{
		return this.httpClient.post<any>(
			'/bff/admin' + PATH + '/'+idUsuario+'/password/reset',
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	  }

	  agregarPointsCustomer(data: any): Observable<string>{
		const body = new HttpParams({encoder: new CustomURLEncoder()})
		.set('points', data.puntos)
		.set('descripcion', data.descripcion);
		return this.httpClient.post<any>(
			'/bff/admin' + PATH + '/'+data.idCustomer+'/points/add',
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	  }

}
