import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IRegionElement } from '../interfaces/iregion-element';

const PATH: string = "/regiones";

@Injectable({
	providedIn: 'root'
})
export class RegionService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getRegions(): Observable<IRegionElement[]> {
		return this.httpClient.get<IRegionElement[]>('/bff/admin' + PATH);
	}
}
