import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { CouponService } from 'src/app/shared/services/coupon.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { IProductElement } from 'src/app/shared/interfaces/iproduct-element';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {

	idComponent: number;
	nameComponent = 'Cupón';
	registerForm: FormGroup;
	loading: boolean = false;
	products: Observable<IProductElement[]>;
	public ckeConfig: any;

	public options = [
		{ value: 1, text: 'Descargar cupón' },
		{ value: 2, text: 'Link' }
	];

	constructor(public dialogRef: MatDialogRef<CouponComponent>,
		private notificationService: NotificationsService,
		private couponService: CouponService,
		private productService: ProductService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		this.ckeConfig = {
			allowedContent: false,
			extraPlugins: 'divarea',
			forcePasteAsPlainText: true,
			toolbarGroups: [
				{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
				{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
				{ name: 'forms', groups: [ 'forms' ] },
				{ name: 'links', groups: [ 'links' ] },
				{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
				{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
				{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
				{ name: 'insert', groups: [ 'insert' ] },
				{ name: 'colors', groups: [ 'colors' ] },
				{ name: 'tools', groups: [ 'tools' ] },
				{ name: 'others', groups: [ 'others' ] },
				{ name: 'about', groups: [ 'about' ] },
				{ name: 'styles', groups: [ 'styles' ] }
			],
			removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
			removeDialogTabs: 'link:advanced'
		};

		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un cupón */
			this.registerForm = this.formBuilder.group({
				$key: null,
				nombre:['', Validators.required],
				titulo: '',
				subtitulo: '',
				imagen: '',
				orden: 1,
				descripcion: '',
				tipo: [1, Validators.required],
				id_producto: [null, Validators.required],
				link: '',
				blank: false,
				activo: [false, Validators.required]
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando un cupón */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			let idComponent = this.data.id_cupon;
			this.idComponent = idComponent;
			/* Guardamos el tipo de cupón en una variable para evitar posibles errores */
			let tipo:number = this.data.tipo;
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				$key: idComponent,
				nombre: [this.data.nombre, Validators.required],
				titulo: this.data.titulo,
				subtitulo: this.data.subtitulo,
				imagen: this.data.imagen,
				orden: this.data.orden,
				descripcion: this.data.descripcion,
				tipo: [tipo, Validators.required],
				id_producto: [this.data.id_producto, tipo == 1 ? Validators.required : null],
				link: this.data.link,
				blank: this.data.blank,
				activo: [this.data.activo, Validators.required]
			});
		}

		/* Se obtienen los productos */
		this.products = this.productService.getProducts();
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('$key').value) {
				this.couponService.store(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					this.notificationService.warn(`:: Error creando cupón: ${error.message}`);
				}).add(() => {
					this.loading = false;
				});
			} else {
				this.couponService.update(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error actualizando cupón: ${error.message}`);
				}).add(() => {
					this.loading = false;
				});
			}
		}
	}


	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

	typeChanged() {
		let idProductoRegisterForm = this.registerForm.get('id_producto');
		switch (this.registerForm.get('tipo').value) {
			case 1:
				idProductoRegisterForm.setValidators(Validators.required);
				break;
			default:
				idProductoRegisterForm.setValidators(null);
				break;
		}
		idProductoRegisterForm.updateValueAndValidity();
	}
}
