import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IVideosElement } from 'src/app/shared/interfaces/ivideos-element';
import { JwtService } from 'src/app/shared/services/jwt.service';
import { Location } from '@angular/common';
import { VideosService } from 'src/app/shared/services/videos.service';
import { CreateVideosComponent } from '../create-videos/create-videos.component';



@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  loading: boolean;
  dataSource = new MatTableDataSource<IVideosElement>();
  selection = new SelectionModel<IVideosElement>(true, []);
  hidden : boolean;
  editVideoComponent : CreateVideosComponent;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private activeRouter: ActivatedRoute,
    private location: Location,
    private navigator: Router,
    protected videosService: VideosService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) {
      this.loading = true;
  }

  public displayedColumns: string[] = [
    // 'select',
    'titulo',
    'fechaCreacion',
    'orden',
    'estado',
    'actions'
  ];

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getVideos();
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }
  }

  getVideos() {
    this.activeRouter.queryParams.subscribe(params => {
    this.hidden = (params.id) ? true : false;
    this.videosService.getVideos(params.id, params.contenido).subscribe((data: any) => {
    this.dataSource.data = data as IVideosElement[];
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
      });
  }
  /* Se abre el modal para crear o editar un video */
  openModal(data ? : any) : void {

      this.dialog.open(CreateVideosComponent, {
        width: '60%',
        height: '85%',
        autoFocus: true,
        disableClose: true,
        data : data
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.loading = true;
          this.getVideos();
        }
      });
    }


/* Borra un video */
  delete(id: number) {
    this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?')
    .afterClosed().subscribe(res => {
      if (res) {
        this.videosService.delete(id).subscribe((data: any) => {
          this.notificationService.message(':: Registro eliminado satisfactoriamente', 'success');
        }, (error) => {
          console.error(error);
        }, () => { this.ngOnInit(); });
      }
    });
  }

    /** Filter rows matches. */
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: IVideosElement): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }


    backTo() {
      localStorage.removeItem('id_contenido');
      this.location.back();
    }
}
