import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IExchangeTypeElement } from '../interfaces/iexchange-type-element';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ExchangesTypeService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getExchangesType(): Observable<IExchangeTypeElement[]> {
		return this.httpClient.get<IExchangeTypeElement[]>('/bff/admin/tipos_canjes');
	}
}