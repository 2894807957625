import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { SegmentoService } from '../../shared/services/segmento.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';

@Component({
	selector: 'app-segmento',
	templateUrl: './segmento.component.html',
	styleUrls: ['./segmento.component.scss']
})
export class SegmentoComponent implements OnInit {

	idComponent: number;
	nameComponent = 'Segmento';
	registerForm: FormGroup;
	loading: boolean = false;
	public ckeConfig: any;

	constructor(
		public dialogRef: MatDialogRef<SegmentoComponent>,
		private notificationService: NotificationsService,
		public segmentoService: SegmentoService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: ISegmentoElement
	) { }

	ngOnInit() {
		this.ckeConfig = {
			allowedContent: false,
			extraPlugins: 'divarea',
			forcePasteAsPlainText: true,
			toolbarGroups: [
				{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
				{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
				{ name: 'forms', groups: [ 'forms' ] },
				{ name: 'links', groups: [ 'links' ] },
				{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
				{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
				{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
				{ name: 'insert', groups: [ 'insert' ] },
				{ name: 'colors', groups: [ 'colors' ] },
				{ name: 'tools', groups: [ 'tools' ] },
				{ name: 'others', groups: [ 'others' ] },
				{ name: 'about', groups: [ 'about' ] },
				{ name: 'styles', groups: [ 'styles' ] }
			],
			removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
			removeDialogTabs: 'link:advanced'
		};

		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un segmento */
			this.registerForm = this.formBuilder.group({
				$key: null,
				nombre: [null, Validators.required],
				descripcion: [null, Validators.required],
				codigo: [null, Validators.required]
			});
		} else {
			this.idComponent = this.data.id_segmento;
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				$key: this.data.id_segmento,
				nombre: this.data.nombre,
				descripcion: this.data.descripcion,
				codigo: this.data.codigo
			});
		}
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('$key').value) {
				this.segmentoService.store(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Enviado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error: any) => {
					this.notificationService.warn(`:: Error creando segmento: ${error.message}`);
				}).add(() => {
					this.loading = false;
				});
			} else {
				this.segmentoService.update(this.registerForm.value).subscribe((res) => {
					this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error actualizando segmento: ${error.message}`);
				}).add(() => {
					this.loading = false;
				})
			}
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}
}
