import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HighlightedBenefitService } from 'src/app/shared/services/highlighted-benefit.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ResourcesService } from 'src/app/shared/services/resources.service';

@Component({
	selector: 'app-highlighted-benefit',
	templateUrl: './highlighted-benefit.component.html',
	styleUrls: ['./highlighted-benefit.component.scss']
})
export class HighlightedBenefitComponent implements OnInit {

	idComponent: number;
	registerForm: FormGroup;
	loading:boolean = false;
	public ckeConfig: any;
	private tipoImagen:string;

	constructor(public dialogRef: MatDialogRef<HighlightedBenefitComponent>,
		private notificationService: NotificationsService,
		private highlightedBenefitService: HighlightedBenefitService,
		private resourcesService: ResourcesService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		this.ckeConfig = {
			allowedContent: false,
			extraPlugins: 'divarea',
			forcePasteAsPlainText: true,
			toolbarGroups: [
				{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
				{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
				{ name: 'forms', groups: [ 'forms' ] },
				{ name: 'links', groups: [ 'links' ] },
				{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
				{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
				{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
				{ name: 'insert', groups: [ 'insert' ] },
				{ name: 'colors', groups: [ 'colors' ] },
				{ name: 'tools', groups: [ 'tools' ] },
				{ name: 'others', groups: [ 'others' ] },
				{ name: 'about', groups: [ 'about' ] },
				{ name: 'styles', groups: [ 'styles' ] }
			],
			removeButtons: 'Save,Templates,NewPage,Preview,Print,PasteText,PasteFromWord,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,Underline,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Anchor,Flash,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,BGColor,TextColor,Maximize,ShowBlocks,About',
			removeDialogTabs: 'link:advanced'
		};

		if (this.data == null) {
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando un sitio */
			this.registerForm = this.formBuilder.group({
				$key: null,
				nombre: ['', Validators.required],
				titulo: ['', Validators.required],
				subtitulo: '',
				descuento: '',
				descripcion: '',
				condiciones: '',
				imagen_logo: ['', Validators.required],
				imagen: ['', Validators.required],
				url: '',
				new_tab: [false, Validators.required],
				orden: ['1', Validators.required],
				dias_semana: this.formBuilder.group({
					diario: [false, Validators.required],
					lunes: [false, Validators.required],
					martes: [false, Validators.required],
					miercoles: [false, Validators.required],
					jueves: [false, Validators.required],
					viernes: [false, Validators.required],
					sabado: [false, Validators.required],
					domingo: [false, Validators.required]
				}),	
				activo: [false, Validators.required]
			});
		} else {
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando una encuesta */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			this.idComponent = this.data.id_beneficio_destacado;
			/* Se llena la información del formulario */
			/* Se da formato a las fechas */
			//let initDate = this.data.fecha_inicio.split(' ');
			//let endDate = this.data.fecha_fin.split(' ');
			let diario = this.data.lunes
				&& this.data.martes
				&& this.data.miercoles
				&& this.data.jueves
				&& this.data.viernes
				&& this.data.sabado
				&& this.data.domingo;
			/* Se registra el formulario */
			this.registerForm = this.formBuilder.group({
				$key: this.idComponent,
				nombre: [this.data.nombre, Validators.required],
				titulo: [this.data.titulo, Validators.required],
				subtitulo: this.data.subtitulo,
				descuento: this.data.descuento,
				descripcion: this.data.descripcion,
				condiciones: this.data.condiciones,
				imagen_logo: [this.data.imagen_logo, Validators.required],
				imagen: [this.data.imagen, Validators.required],
				url: this.data.url,
				new_tab: this.data.new_tab,
				orden: [this.data.orden, Validators.required],
				dias_semana: this.formBuilder.group({
					diario: [diario, Validators.required],
					lunes: [this.data.lunes, Validators.required],
					martes: [this.data.martes, Validators.required],
					miercoles: [this.data.miercoles, Validators.required],
					jueves: [this.data.jueves, Validators.required],
					viernes: [this.data.viernes, Validators.required],
					sabado: [this.data.sabado, Validators.required],
					domingo: [this.data.domingo, Validators.required]
				}),	
				activo: [this.data.activo, Validators.required]
			});
		}
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			if (!this.registerForm.get('$key').value) {
				this.highlightedBenefitService.store(this.registerForm.value).subscribe((res) => {
					this.notificationService.success(':: Enviado satisfactoriamente');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error creando: ${error.error.message}`);
				}).add(() => this.loading = false);
			} else {
				this.highlightedBenefitService.update(this.registerForm.value).subscribe((res) => {
					this.notificationService.success(':: Actualizado satisfactoriamente');
					this.onClose(true);
				}, (error) => {
					this.notificationService.warn(`:: Error actualizando: ${error.error.message}`);
				}).add(() => this.loading = false);
			}
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

	diarioChanged() {
		let diasSemanaFormGroup = this.registerForm.get('dias_semana') as FormGroup;
		let diarioFormControl= diasSemanaFormGroup.get('diario');
		Object.keys(diasSemanaFormGroup.controls).forEach(key => {
			if (key != 'diario') {
				diasSemanaFormGroup.get(key).setValue(diarioFormControl.value);
			}
		});
	}

	diaChanged() {
		let diasSemanaFormGroup = this.registerForm.get('dias_semana') as FormGroup;
		let primerElemento = true;
		let diario:boolean;
		Object.keys(diasSemanaFormGroup.controls).forEach(key => {
			if (key != 'diario') {
				let diaSemanaValue = diasSemanaFormGroup.get(key).value;
				if (primerElemento) {
					diario = diaSemanaValue;
					primerElemento = false;
				} else {
					diario = diario && diaSemanaValue;
				}
			}
		});
		diasSemanaFormGroup.get('diario').setValue(diario);
	}

	enableNewTab() {
		const url = this.registerForm.get('url').value;
		const newTab = this.registerForm.get('new_tab');
		if (url == null || url.trim() === '') {
		const newTab = this.registerForm.get('new_tab');
			newTab.disable();
		} else {
			newTab.enable();
		}
	}
}
