import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ProductService } from '../../shared/services/product.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ExpensesService } from '../../shared/services/expense.service';
import { TicketsService } from '../../shared/services/tickets.service';
import { JwtService } from 'src/app/shared/services/jwt.service';
import * as $ from "jquery";
import * as XLSX from 'xlsx';

import * as _moment from 'moment';
import 'moment/locale/es';

const moment = _moment;
moment.locale('es');

@Component({
  selector: 'app-ticket-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['./expense-list.component.scss']
})


export class ExpenseListComponent implements OnInit {
  
  loading: boolean;
  monitoreados: string;
  dataSource = new MatTableDataSource<any>();
  dataSource2 = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  datatickets = new Array();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public displayedColumns: string[] = [
    'empresa',
    'fecha',
    'cantidad',
    //'cantida_maxima_canje',
    //'categoria_producto'
  ];

  constructor(
    protected expenseService: ExpensesService,
    protected ticketService: TicketsService,
    protected productService: ProductService,
    private dialog: MatDialog,
    private dialogConfirm: DialogService,
    private notificationService: NotificationsService,
    protected jwtService: JwtService
  ) { }

  ngOnInit() {
    if (!this.jwtService.isTokenExpired) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

     
      this.inititate_boxes();
      this.get_mes_acumulado();
      this.get_anio_acumulado();
      this.get_saldo_disponible();
      
    } else {
      this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
    }

   
  }


  inititate_boxes(){
    var hoy = new Date();
    var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    var mes = meses[hoy.getMonth()];
    $("#accumulated_title").html(  'Aculmulado '+hoy.getFullYear()+' <br> GiftCard'  );
    $("#acummulated_month_title").html(  mes+' '+hoy.getFullYear() );
  }

  get_saldo_disponible(){

    var total = 0;

    this.ticketService.getTickets().subscribe((data: any) => {
      this.datatickets = data;
      data.forEach( ( x ) => {
        //console.log(x.nombre);
       
        x.productos.forEach( ( item ) => {
          //console.log(item.id_producto, item.nombre, item.stock, item.canjeados, item.precio);
          total += (item.precio * item.stock); 
        });

      });


     
    }, (error: any) => {
      console.error(error);
    }, () => {
      $("#saldo_disponible_label").html("$"+total.toLocaleString().toString()); 
    });
  
  }
  get_anio_acumulado(){
    var total = 0;
    var f1 = new Date();
    var prevday = new Date(f1.getFullYear(),0,1).toISOString().slice(0, 10);

    var f2 = new Date();
    var nextday = new Date(f2.getFullYear(),11,31).toISOString().slice(0, 10);

    this.expenseService.getQuemados(prevday,nextday).subscribe((data: any) => {
      data.forEach(item => 
        this.productService.getProduct(item.id_producto).subscribe((data2: any) => {
          total += (data2.precio * item.cantidad);
        }, (error: any) => {
          console.error(error);
        }, () => { $("#total_anio").html("$"+total.toLocaleString().toString()); })
      );
    }, (error: any) => {
      console.error(error);
    }, () => { });
  }
  get_mes_acumulado(){
    var total = 0;
    var f1 = new Date();
    var prevday = new Date(f1.getFullYear(),f1.getMonth(),1).toISOString().slice(0, 10);

    var f2 = new Date();
    var nextday = new Date(f2.getFullYear(),f2.getMonth()+1,0).toISOString().slice(0, 10);

    this.expenseService.getQuemados(prevday,nextday).subscribe((data: any) => {
      data.forEach(item => 
        this.productService.getProduct(item.id_producto).subscribe((data2: any) => {
          total += (data2.precio * item.cantidad);
        }, (error: any) => {
          console.error(error);
        }, () => {  $("#total_mes").html("$"+total.toLocaleString().toString()); })
      );
    }, (error: any) => {
      console.error(error);
    }, () => { });
  }

  buscar() {
    this.dataSource = new MatTableDataSource();
    console.log( $("#fecha1").val());
    console.log($("#fecha2").val());
    var f2 = new Date($("#fecha2").val().toString());
    var nextday = new Date(f2.getFullYear(),f2.getMonth(),f2.getDate()+1).toISOString().slice(0, 10);;



    this.getQuemados($("#fecha1").val(),nextday);
    //console.log(this.monitoreados);

   console.log(this.dataSource.data);
  }

  getExp() {
    var f2 = new Date($("#fecha2").val().toString());
    var nextday = new Date(f2.getFullYear(),f2.getMonth(),f2.getDate()+1).toISOString().slice(0, 10);;


    this.expenseService.getQuemados($("#fecha1").val(), nextday).subscribe((data: any) => {
      //console.log(data as any[]);
      var readyToExport = data as any[];
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.json_to_sheet(readyToExport);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'quemados'); 
    XLSX.writeFile(workBook, 'Quemados '+$("#fecha1").val()+'  '+$("#fecha2").val()+'.xlsx'); // initiate a file download in browser


    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; });
  }



  getQuemados(f1,f2){
    var result = {};
    
    this.expenseService.getQuemados(f1,f2).subscribe((data: any) => {
      this.dataSource.data =  data as any[];
      
      
      
    }, (error: any) => {
      console.error(error);
    }, () => { this.loading = false; console.log(this.dataSource.data); });
  }



}
