import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MasterConfigurationService } from '../shared/services/master-configuration.service';
import { NotificationsService } from '../shared/services/notifications.service';
import { JwtService } from 'src/app/shared/services/jwt.service';

@Component({
  selector: 'app-master-configuration',
  templateUrl: './master-configuration.component.html',
  styleUrls: ['./master-configuration.component.scss']
})
export class MasterConfigurationComponent implements OnInit {

	registerForm: FormGroup;
	loading:boolean = true;
	saving: boolean = false;
	
	constructor(private masterConfigurationService:MasterConfigurationService,
		private notificationService: NotificationsService,
		protected jwtService: JwtService,
		private dialogService: DialogService,
		private formBuilder: FormBuilder) {	}

	ngOnInit() {
		if (!this.jwtService.isTokenExpired) {
			this.masterConfigurationService.getMasterConfiguration(1).subscribe((data:any) => {
				this.registerForm = this.formBuilder.group({
					$key: 1,
					views_enabled: data.views_enabled,
					exchanges_enabled: data.exchanges_enabled,
					disable_exchange_start_date: data.disable_exchange_start_date,
					disable_exchange_end_date: data.disable_exchange_end_date,
					max_daily_exchanges: [data.max_daily_exchanges, [Validators.min(1), Validators.max(255)]],
					max_monthly_exchanges: [data.max_monthly_exchanges, [Validators.min(1), Validators.max(255)]]
				});
			}, (error) => {
				this.notificationService.warn(`:: Error obteniendo configuración maestra: ${error.message}`);
			}, () => {
				this.loading = false;
			});
		} else {
			this.notificationService.warn(':: Sesión expirada. Por favor, vuelva a iniciar sesión');
		}
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.dialogService.openConfirmDialog('Estás a punto de modificar la configuración maestra, ¿estás seguro de esto?').afterClosed().subscribe(res => {
				if (res) {
					this.saving = true;
					this.masterConfigurationService.update(this.registerForm.value).subscribe((res) => {
						this.notificationService.message(':: Actualizado satisfactoriamente', 'success');
					}, (error) => {
						this.notificationService.warn(`:: Error actualizando: ${error.error.message}`);
					}).add( () => {
						this.saving = false
					});
				}
			});
		}
	}
}
