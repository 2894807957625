import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IProductElement } from '../../shared/interfaces/iproduct-element';
import { DialogService } from '../../shared/services/dialog.service';
import { JwtService } from '../../shared/services/jwt.service';
import { NotificationsService } from '../../shared/services/notifications.service';
import { SubnavbarService } from '../../shared/services/subnavbar.service';
import { ProductService } from '../../shared/services/product.service';
import { IProductData } from '../IProductData';
import { ProductComponent } from '../product/product.component';

@Component({
	selector: 'app-product-list',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

	loading: boolean = true;
	idSubmenu: number;
	dataSource = new MatTableDataSource<IProductElement>();
	selection = new SelectionModel<IProductElement>(true, []);

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public displayedColumns: string[] = [
		'nombre',
		'titulo',
		'fecha_inicio',
		'fecha_fin',
		'imagen',
		'estado',
		'actions'
	];

	constructor(
		private activeRouter: ActivatedRoute,
		private productService: ProductService,
		private submenuService: SubnavbarService,
		private dialog: MatDialog,
		private dialogConfirm: DialogService,
		private notificationService: NotificationsService,
		private location: Location,
		protected jwtService: JwtService
	) { }

	ngOnInit(): void {
		if (!this.jwtService.isTokenExpired) {
			this.activeRouter.queryParams.subscribe(params => {
				this.idSubmenu = params.id_submenu;
			});
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.getProducts();
		} else {
			this.notificationService.message(':: Sesión expirada. Por favor, vuelva a iniciar sesión', 'warn');
		}
	}

	getProducts() {
		this.loading = true; 
		let productsObservable: Observable<IProductElement[]>;
		if (this.idSubmenu) {
			productsObservable = this.submenuService.getSubmenuProducts(this.idSubmenu);
		} else {
			productsObservable =  this.productService.getProducts()
		}
		productsObservable.subscribe((data) => {
			this.dataSource.data = data;
		}, (error: any) => {
			this.notificationService.warn(`:: Error obteniendo productos: ${error.message}`);
		}).add(() => {
			this.loading = false;
		});
	}

	openModal(producto?: IProductElement) {
		const data: IProductData = {
			idSubmenu: this.idSubmenu,
			producto: producto
		}
		this.dialog.open(ProductComponent, {
			width: '60%',
			height: '85%',
			autoFocus: true,
			disableClose: true,
			data: data
		}).afterClosed().subscribe((res) => {
			if (res) {
				this.getProducts();
			}
		});
	}

	delete(id: number) {
		this.dialogConfirm.openConfirmDialog('¿Estás seguro de eliminar este registro?').afterClosed().subscribe(res => {
			if (res) {
				this.productService.delete(id).subscribe((data: any) => {
					this.notificationService.success(':: Registro eliminado satisfactoriamente');
				}, (error) => {
					this.notificationService.warn(`:: Error eliminando producto: ${error.message}`);
				}).add(() => {
					this.getProducts();
				});
			}
		});
	}

	backTo() {
		this.location.back();
	}

	/** Filter rows matches. */
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

}
