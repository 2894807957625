import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { SubnavbarService } from '../../shared/services/subnavbar.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ProductService } from 'src/app/shared/services/product.service';
import { SiteService } from 'src/app/shared/services/site.service';
import { IProductElement } from 'src/app/shared/interfaces/iproduct-element';
import { SubmenusTypeService } from 'src/app/shared/services/submenus-type.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';
import { IContenidosElement } from 'src/app/shared/interfaces/icontenidos-element';
import { SegmentoService } from 'src/app/shared/services/segmento.service';
import { ISiteElement } from 'src/app/shared/interfaces/isite-element';
import { ISubNavBarElement } from 'src/app/shared/interfaces/isubnavbar-element';
import { ISubmenuTypeElement } from 'src/app/shared/interfaces/isubmenu-type-element';
import { ISegmentTypeElement } from 'src/app/shared/interfaces/isegment-type-element';
import { CSegmentsConstants } from 'src/app/shared/constants/csegments-constants';
import { ContenidosService } from 'src/app/shared/services/contenidos.service';

export interface Color {
	id: number;
	value: string;
}



@Component({
	selector: 'app-submenu',
	templateUrl: './submenu.component.html',
	styleUrls: ['./submenu.component.scss']
})
export class SubmenuComponent implements OnInit {

	registerForm: FormGroup;
	loading: boolean;
	nameComponent = 'Submenú';

	submenuTypes: ISubmenuTypeElement[] = [];

	colors: Color[] = [
		{id: 1, value: 'hover-red-bg'},
		{id: 2, value: 'hover-purple-bg'},
		{id: 3, value: 'hover-blue-bg'},
		{id: 4, value: 'hover-orange-bg'},
		{id: 5, value: 'hover-green-bg'},
		{id: 6, value: 'hover-pink-bg'}
	];

	contenido:IContenidosElement;
	contenidos:IContenidosElement[];
	selectedContenido:any;
	loadingContenidos=false;
	loadingSubmenuContenidos=false;

	products: IProductElement[];
	loadingProducts: boolean = true;
	loadingSubmenuProducts: boolean = true;

	sites: ISiteElement[] = [];
	loadingSites: boolean = true;
	loadingSubmenuSites: boolean = true;

	tiposPerfilamientos: ISegmentTypeElement[] = CSegmentsConstants.TiposPerfilamientos;
	segmentos: ISegmentoElement[] = [];
	loadingSegmentos: boolean = true;

	constructor(
		public dialogRef: MatDialogRef<SubmenuComponent>,
		private notificationService: NotificationsService,
		public subNavBarService: SubnavbarService,
		private productService: ProductService,
		private siteService: SiteService,
		private submenusTypeService: SubmenusTypeService,
		private formBuilder: FormBuilder,
		private contenidosService : ContenidosService,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		private segmentosService : SegmentoService
	) {	}

	ngOnInit() {
		if (this.data == null) {
			this.loadingSubmenuProducts = false;
			this.loadingSubmenuSites = false;
			/* Si al abrir el modal (iniciar el componente) el parámetro es nulo, se está creando una encuesta */
			this.registerForm = this.formBuilder.group({
				$key: null,
				name: ['', [Validators.required, Validators.minLength(3)]],
				str_index: [''],
				orden: [1, Validators.required],
				link: [''],
				color: ['#000000'],
				color_borde: ['#000000'],
				color_hover: [''],
				id_submenu_type: [{value: 1, disabled: true}, Validators.required],
				icon: [''],
				image: [''],
				description: [''],
				active: false,
				visible_top: true,
				visible_login: false,
				id_producto: [[]],
				id_sitio: [[]],
				tipo_perfilamiento: 1,
				id_segmento: [[]],
				idListContenidos: [[]]
			});
		} else {
			//const noContenidos = (this.registerForm.get('idListContenidos').value == null ? true : false);
			const buscarConSubmenu = true;
			/* Si al abrir el modal (iniciar el componente) el parámetro no es nulo, se está editando una encuesta */
			/* Se setea el idComponent para que FormToolbarComponent sepa que se está editando */
			let idComponent = this.data.id;
			/* Se llena la información del formulario */
			/* Se llena la información de los segmentos */
			let selectedSegmentos:number[] = [];
			let segmentosArray = this.data.segmentos;
			if (segmentosArray instanceof Array) {
				segmentosArray.forEach(element => {
					selectedSegmentos.push(element.id_segmento);
				});
			}
			this.registerForm = this.formBuilder.group({
				$key: idComponent,
				name: [this.data.nombre, [Validators.required, Validators.minLength(3)]],
				str_index: this.data.strIndex,
				orden: [this.data.orden, Validators.required],
				link: this.data.link,
				color: this.data.color,
				color_borde: this.data.color_borde,
				color_hover: this.data.color_hover,
				id_submenu_type: [{value: this.data.id_tipo_submenu, disabled: true}, Validators.required],
				icon: this.data.icono,
				image: this.data.imagen,
				description: this.data.descripcion,
				active: this.data.estado == 1 ? true : false,
				visible_top: this.data.visibleTop,
				visible_login: this.data.visibleOnlyLogin,
				id_producto: [[]],
				id_sitio: [[]],
				idListContenidos: [[]],
				tipo_perfilamiento: this.data.tipo_perfilamiento,
				id_segmento: [selectedSegmentos]
			});
			/* Se obtienen los productos del submenú */
			this.subNavBarService.getSubmenuProducts(idComponent).subscribe((res) => {
				let selectedProducts:number[] = [];
				res.forEach(iProductElement => {
					selectedProducts.push(iProductElement.id_producto);
				});
				this.registerForm.controls.id_producto.setValue(selectedProducts);
				this.loadingSubmenuProducts = false;
				this.refreshLoading();
			}, (error: any) => {
				this.notificationService.warn(`:: Error obteniendo productos de submenú: ${error.message}`);
			});
			/* Se obtienen los sitios del submenú */
			this.subNavBarService.getSubmenuSites(idComponent).subscribe((res) => {
				let selectedSites:number[] = [];
				res.forEach(iSiteElement => {
					selectedSites.push(iSiteElement.id_sitio);
				});
				this.registerForm.controls.id_sitio.setValue(selectedSites);
				this.loadingSubmenuSites = false;
				this.refreshLoading();
			}, (error: any) => {
				this.notificationService.warn(`:: Error obteniendo sitios de submenú: ${error.message}`);
			});
			/* Se obtienen los contenidos del submenú */
			this.subNavBarService.getSubmenuContenidos(idComponent).subscribe((res) => {
				let selectedContenidos:number[] = [];
				res.forEach(iContenidosElement => {
					selectedContenidos.push(iContenidosElement.id);
				});
				this.registerForm.controls.idListContenidos.setValue(selectedContenidos);
				this.loadingSubmenuContenidos = false;
				this.refreshLoading();
			}, (error: any) => {
				this.notificationService.warn(`:: Error obteniendo contenidos de submenú: ${error.message}`);
			});
		}
		// Se cargan todos los tipos de submenú
		this.submenusTypeService.getSubmenusType().subscribe((res) => {
			this.submenuTypes = res;
			this.registerForm.controls['id_submenu_type'].enable();
			this.refreshLoading();
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo lista de tipos de submenús: ${error.message}`);
		});
		// Se cargan todos los productos
		this.productService.getProducts().subscribe((res) => {
			this.products = res;
			this.loadingProducts = false;
			this.refreshLoading();
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo lista de productos: ${error.message}`);
		});

		// Se cargan todos los sitios
		this.siteService.getSites().subscribe((res) => {
			this.sites = res;
			this.loadingSites = false;
			this.refreshLoading();
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo lista de sitios: ${error.message}`);
		});
		// Se cargan todos los segmentos
		this.segmentosService.getSegmentos().subscribe((res) => {
			this.segmentos = res;
			this.loadingSegmentos = false;
			this.refreshLoading();
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo lista de segmentos: ${error.message}`);
		});
		// Se cargan todos los contenidos
		this.contenidosService.getContenidos().subscribe((res) => {
			this.contenidos = res;
			this.loadingContenidos = false;
			this.refreshLoading();
		}, (error) => {
			this.notificationService.warn(`:: Error obteniendo lista de contenidos: ${error.message}`);
		});
	}



	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			let saveSubmenuObservable: Observable<ISubNavBarElement>;
			if (!this.registerForm.get('$key').value) {
				saveSubmenuObservable = this.subNavBarService.store(this.registerForm.value);
			} else {
				saveSubmenuObservable = this.subNavBarService.update(this.registerForm.value);
			}
			saveSubmenuObservable.subscribe(() => {
				this.notificationService.success(':: Guardado satisfactoriamente');
				this.onClose(true);
			}, (error) => {
				this.notificationService.warn(`:: Error guardando submenú: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	private refreshLoading() {
		this.loading = this.registerForm.controls['id_submenu_type'].disabled || this.loadingProducts || this.loadingSubmenuProducts
			|| this.loadingSites || this.loadingSubmenuSites || this.loadingSegmentos;
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset(this.subNavBarService.initialValues);
		this.dialogRef.close(confirmation);
	}

}
