import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {IQuestionTypeElement} from '../../shared/interfaces/iquestion-type-element';

@Injectable({
  providedIn: 'root'
})
export class QuestionsTypeService {

  constructor(
	  private httpClient: HttpClient
  ) { }

  	getQuestionsTypes(): Observable<IQuestionTypeElement[]> {
		return this.httpClient.get<IQuestionTypeElement[]>('/bff/admin/tipos-preguntas-encuestas');
	}
}
