import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/shared/services/users.service';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { ProfileService } from '../../shared/services/profile.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  loading: boolean;
  estado: boolean;
  submitted: boolean;
  profiles: any;
  public reset: boolean;
  public idComponent: number;
  public nameComponent = 'Usuario';

  constructor(
    public userService: UsersService,
    public profileService: ProfileService,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private notificationService: NotificationsService
  ) {
    this.estado = false;
    this.idComponent = this.userService.registerForm.get('id_usuario_cms').value;
   }

  ngOnInit() {
    this.reset = this.userService.registerForm.get('id_usuario_cms').value;
    // PROFILES
    this.profileService.getProfiles().subscribe((res) => {
      this.profiles = res;
    }, (error: any) => {
      console.error(error);
    }, () => {
      const profile = _.find(this.profiles, (o) => o.id_perfil === this.userService.registerForm.get('perfil').value);
      this.userService.registerForm.get('perfil').setValue(profile);
    });
  }

  onSubmit() {
    if (this.userService.registerForm.valid) {
      if (!this.userService.registerForm.get('id_usuario_cms').value) {
        this.userService.store(this.userService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.onClose(true);
        }, (error: any) => {
          console.error(error);
        }, () => {
          this.notificationService.message(':: Registro guardado satisfactoriamente', 'success');
        });
      } else {
        this.userService.update(this.userService.registerForm.value).subscribe((res: any) => {
          this.submitted = true;
          this.onClose(true);
        }, (error) => {
          console.error(error);
        }, () => {
          this.notificationService.message(':: Registro actualizado satisfactoriamente', 'success');
        });
      }
    }
  }

  get f() { return this.userService.registerForm.controls; }

  onChange($event: any) {
    this.estado = $event.checked;
  }

  onClose(confirmation: boolean) {
    this.userService.registerForm.reset();
    this.dialogRef.close(confirmation);
  }
}
