import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DetailFormatService {

  constructor(
    protected httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) { }

  detailFormatForm: FormGroup = this.formBuilder.group({
    id: null,
    title: ['', [Validators.required, Validators.minLength(3)]],
    detail: ['', [Validators.required, Validators.minLength(10)]],
    type: ['', Validators.required],
    orientation: ['', Validators.required],
    id_product: ['', Validators.required]
  });

  getDetailsFormat() {
    return this.httpClient.get('/bff/admin/formatodetalleproductos');
  }

  edit(data: any) {
    console.log(data);
    this.detailFormatForm.setValue(data);
  }

  store(data: any) {
    console.log(data);

    const body = new HttpParams()
      .set('titulo', data.title)
      .set('detalle', data.detail)
      .set('tipo', data.type.id)
      .set('finalidad', data.orientation.id)
      .set('estado', '1')
      .set('id_producto', data.id_product);

    return this.httpClient.post<any>(
      '/bff/admin/formatodetalleproductos',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
    );
  }

  update(data: any) {
    console.log(data);
    const body = new HttpParams()
      .set('id_formato_detalle_producto', data.id)
      .set('titulo', data.title)
      .set('detalle', data.detail)
      .set('tipo', data.type.id)
      .set('finalidad', data.orientation.id)
      .set('estado', '1')
      .set('id_producto', data.id_product.id_producto);

    return this.httpClient.put<any>(
      '/bff/admin/formatodetalleproductos',
      body.toString(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') });
  }

  delete(id: number) {
    return this.httpClient.delete('/bff/admin/formatodetalleproductos/' + id);
  }
}
