import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISubmenuTypeElement } from '../interfaces/isubmenu-type-element';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SubmenusTypeService {

	constructor(
		protected httpClient: HttpClient
	) { }

	getSubmenusType(): Observable<ISubmenuTypeElement[]> {
		return this.httpClient.get<ISubmenuTypeElement[]>('/bff/admin/tipo_submenu');
	}
}
