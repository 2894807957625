import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CustomURLEncoder } from '../../urlencoder/component';
import { Observable } from 'rxjs';
import { INavBarElement } from '../interfaces/inavbar-element';
import { ISubNavBarElement } from '../interfaces/isubnavbar-element';

const PATH: string = "/menus";

@Injectable({
	providedIn: 'root'
})

export class NavbarService {
	
	constructor(protected httpClient: HttpClient) { }

	getNavBars(): Observable<INavBarElement[]> {
		return this.httpClient.get<INavBarElement[]>(`/bff/admin${PATH}`);
	}

	getSubmenus(id: number): Observable<ISubNavBarElement[]>  {
		return this.httpClient.get<ISubNavBarElement[]>(`/bff/admin${PATH}/${id}/scsubmenus`);
	}

	storeMenu(data: any): Observable<INavBarElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('color', data.color)
			.set('orden', data.orden)
			.set('estado', data.estado ? '1' : '0')
			.set('link', data.link)
			.set('strIndex', data.strIndex)
			.set('nombre', data.nombre)
			.set('tipo', data.tipo)
			.set('userElement', data.userElement)
			.set('visibleOnlyLogin', data.visibleOnlyLogin)
			.set('visibleTop', data.visibleTop)
			.set('tipo_perfilamiento', data.tipo_perfilamiento)
			.set('id_segmento', data.id_segmento);
		return this.httpClient.post<INavBarElement>(
			`/bff/admin${PATH}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
		);
	}

	updateMenu(data: any): Observable<INavBarElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('color', data.color)
			.set('orden', data.orden)
			.set('estado', data.estado ? '1' : '0')
			.set('link', data.link)
			.set('strIndex', data.strIndex)
			.set('nombre', data.nombre)
			.set('tipo', data.tipo)
			.set('userElement', data.userElement)
			.set('visibleOnlyLogin', data.visibleOnlyLogin)
			.set('visibleTop', data.visibleTop)
			.set('tipo_perfilamiento', data.tipo_perfilamiento)
			.set('id_segmento', data.id_segmento);
		return this.httpClient.put<INavBarElement>(
			`/bff/admin${PATH}/${data.id}`,
			body.toString(),
			{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
		);
	}

	deleteMenu(id: number) {
		return this.httpClient.delete(`/bff/admin${PATH}/${id}`);
	}

}
