import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { NavbarService } from 'src/app/shared/services/navbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISegmentoElement } from 'src/app/shared/interfaces/isegmento-element';
import { SegmentoService } from 'src/app/shared/services/segmento.service';
import { INavBarElement } from 'src/app/shared/interfaces/inavbar-element';
import { Observable } from 'rxjs/internal/Observable';
import { ISegmentTypeElement } from 'src/app/shared/interfaces/isegment-type-element';
import { CSegmentsConstants } from 'src/app/shared/constants/csegments-constants';

interface ITypeElement {
	id: number;
	value: string;
}

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

	public loading: boolean = false;
	public nameComponent = 'Menú';
	public registerForm: FormGroup;

	tiposPerfilamientos: ISegmentTypeElement[] = CSegmentsConstants.TiposPerfilamientos;
	segmentos: ISegmentoElement[] = [];
	loadingSegmentos: boolean = true;

	types: ITypeElement[] = [
		{id: 1, value: 'TIPO LINK'},
		{id: 2, value: 'TIPO CATEGORIA'}
	];
	
	constructor(
		public dialogRef: MatDialogRef<MenuComponent>,
		private notificationService: NotificationsService,
		public navBarService: NavbarService,
		private formBuilder: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: INavBarElement,
		private segmentosService : SegmentoService
	) {	}

	ngOnInit() {
		if (this.data == null) {
			this.registerForm = this.formBuilder.group({
				id: null,
				nombre: ['', [Validators.required, Validators.minLength(3)]],
				strIndex: ['', Validators.required],
				color: ['#000000', Validators.required],
				orden: ['1', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
				tipo: [1, Validators.required],
				link: ['', Validators.required],
				estado: [false, Validators.required],
				visibleTop: [true, Validators.required],
				visibleOnlyLogin: [false, Validators.required],
				userElement: [false, Validators.required],
				tipo_perfilamiento: [1, Validators.required],
				id_segmento: [[]]
			});
		} else {
			/* Se llena la información de los segmentos */
			let selectedSegmentos:number[] = [];
			let segmentosArray = this.data.segmentos;
			if (segmentosArray instanceof Array) {
				segmentosArray.forEach(element => {
					selectedSegmentos.push(element.id_segmento);
				});
			}
			this.registerForm = this.formBuilder.group({
				id: this.data.id,
				nombre: [this.data.nombre, [Validators.required, Validators.minLength(3)]],
				strIndex: [this.data.strIndex, Validators.required],
				color: [this.data.color, Validators.required],
				orden: [this.data.orden, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
				tipo: [this.data.tipo, Validators.required],
				link: [this.data.link, Validators.required],
				estado: [this.data.estado, Validators.required],
				visibleTop: [this.data.visibleTop, Validators.required],
				visibleOnlyLogin: [this.data.visibleOnlyLogin, Validators.required],
				userElement: [this.data.userElement, Validators.required],
				tipo_perfilamiento: [this.data.tipo_perfilamiento, Validators.required],
				id_segmento: [selectedSegmentos]
			});
		}

		this.segmentosService.getSegmentos().subscribe((res) => {
			this.loadingSegmentos = false;
			this.segmentos = res;
		});
	}

	onSubmit() {
		if (this.registerForm.valid) {
			this.loading = true;
			let saveMenuObservable: Observable<INavBarElement>;
			if (!this.registerForm.get('id').value) {
				saveMenuObservable = this.navBarService.storeMenu(this.registerForm.value);
			} else {
				saveMenuObservable = this.navBarService.updateMenu(this.registerForm.value);
			}
			saveMenuObservable.subscribe(() => {
				this.notificationService.success(':: Guardado satisfactoriamente');
				this.onClose(true);
			}, (error) => {
				this.notificationService.warn(`:: Error guardando menú: ${error.message}`);
			}).add(() => {
				this.loading = false;
			});
		}
	}

	onClose(confirmation: boolean) {
		this.registerForm.reset();
		this.dialogRef.close(confirmation);
	}

}