import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IVideosElement } from 'src/app/shared/interfaces/ivideos-element';
// import { VideoClass } from 'src/app/videos/VideoClass';
// import { Observable } from 'rxjs';
import { CustomURLEncoder } from 'src/app/urlencoder/component';
import { ContenidosService } from './contenidos.service';
@Injectable({
  providedIn: 'root'
})
export class VideosService {
  video :IVideosElement;
  private urlEndPoint: string ='/bff/admin/videos';
  initialValues: any;
  tags: Array<number>;
  rutaImagenDefault : string ='videocopec.jpg';
  contenidosService : ContenidosService;
  /*private httpHeaders = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'});*/

  constructor(protected httpClient: HttpClient){}
  /* Obtener todos los videos o videos según contenido */
    getVideos(id?: number, contenido: boolean = false) {
      if (contenido) {
        return this.httpClient.get('/bff/admin/contenidos/'+id+'/videos');
      } else {
        if (!id) {
          return this.httpClient.get(this.urlEndPoint);
        } else {
          return this.httpClient.get(this.urlEndPoint + '/' + id);
        }
      }

    }

    /*
    create(video : VideoClass) : Observable<VideoClass>{
      console.log('Observer got a complete notification');
      return this.httpClient.post<VideoClass>(this.urlEndPoint, video,{headers: this.httpHeaders})
    }
    */

    create(data: any) {

      this.tags =  [];

      if (Array.isArray(data.tags)) {
        data.tags.forEach((value: any, key: any) => {
          this.tags.push(value.id_tag_producto);
        });
      }

      const body = new HttpParams({encoder: new CustomURLEncoder()})
        .set('titulo', data.titulo)
        .set('orden', data.orden)
        .set('estado', (data.estado) ? '1' : '0')
        .set('url', data.url)
        .set('descripcion', data.descripcion)
        .set('id_tag', this.tags.join(','))
        .set('rutaImagenPrevia', data.rutaImagenPrevia)
        .set('zonaEds', data.zonaEds)
        .set('responsable', data.responsable);
      return this.httpClient.post<any>(
        this.urlEndPoint,
        body.toString(),
        { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
      );
    }

    /* Actualiza un video */
      update(data: any) {

		this.tags =  [];

    if (Array.isArray(data.tags)) {
      data.tags.forEach((value: any, key: any) => {
        this.tags.push(value.id_tag_producto);
      });
	}

      const body = new HttpParams({encoder: new CustomURLEncoder()})
      .set('titulo', data.titulo)
      .set('orden', data.orden)
      .set('estado', (data.estado) ? '1' : '0')
      .set('url', data.url)
      .set('descripcion', data.descripcion)
      .set('id_tag', this.tags.join(','))
      .set('rutaImagenPrevia', data.rutaImagenPrevia)
      .set('zonaEds', data.zonaEds)
      .set('responsable', data.responsable);
      return this.httpClient.put<any>(
        this.urlEndPoint + '/' + data.id,
        body.toString(),
        { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8') }
      );
    }

    delete(id: number) {
      return this.httpClient.delete(this.urlEndPoint + '/' + id);
    }

}
