import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {CustomURLEncoder} from '../../urlencoder/component';
import { Observable, of } from 'rxjs';
import { ITagElement } from '../interfaces/itag-element';
import { IIconElement } from '../interfaces/iicon-element';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

	constructor(protected httpClient: HttpClient) { }

	getTags(): Observable<ITagElement[]> {
		return this.httpClient.get<ITagElement[]>('/bff/admin/tags_productos');
	}

	store(data: any): Observable<ITagElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('descripcion', data.descripcion)
			.set('icono', data.icono)
			.set('estado', data.estado);

		return this.httpClient.post<ITagElement>(
		'/bff/admin/tags_productos',
		body.toString(),
		{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	update(data: any): Observable<ITagElement> {
		const body = new HttpParams({encoder: new CustomURLEncoder()})
			.set('nombre', data.nombre)
			.set('descripcion', data.descripcion)
			.set('icono', data.icono)
			.set('estado', data.estado);

		return this.httpClient.put<ITagElement>(
		'/bff/admin/tags_productos/' + data.id_tag_producto,
		body.toString(),
		{ headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8') }
		);
	}

	delete(id: number) {
		return this.httpClient.delete('/bff/admin/tags_productos/' + id);
	}

	getIcons(): Observable<IIconElement[]> {
		return this.httpClient.get<IIconElement[]>('/bff/public/resource/json?name=icons.json');
	}
}
